import { map, orderBy } from 'lodash';
import chain from 'lib-frontend-shared/src/helpers/chain';
import moment from '../helpers/moment';
import { carriyoClient } from './base';

/* eslint-disable-next-line import/prefer-default-export */
export const getHistoryChangeLogs = async ({
  entityType,
  id,
}) => {
  const { data = [] } = await carriyoClient.get(`/changelogs/${entityType}${id ? `/${id}` : ''}`);
  return {
    data: chain(data)
      .fn(map, ({
        log_date: logDate,
        action: event,
        entity,
        source_id: sourceId,
        source_type: sourceType,
      }) => {
        const {
          updateDate,
          updatedByUser: author,
          ...state
        } = JSON.parse(entity);
        return {
          author,
          event,
          sourceId,
          sourceType,
          state,
          timestamp: logDate || updateDate,
        };
      })
      .fn(orderBy, [({ timestamp }) => moment(timestamp).valueOf()], ['desc'])
      .value,
  };
};
