import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import { fromCarrier } from '../api-transforms';
import { CLICKNSHIP_TENANT_ID } from '../enums';
import { carriyoClient, nodeMiddlewareClient } from './base';

export const getAllClicknShipServices = async () => {
  const { data = [] } = await carriyoClient.get('/clicknship-services');
  return data.map(camelCaseKeys);
};


export const getClicknShipServiceById = async (serviceId) => {
  const { data } = await carriyoClient.get(`/clicknship-services/${serviceId}`);
  return camelCaseKeys(data);
};


export const getAllServiceAssociatedCarriers = async ({ status } = {}) => {
  const { data: { items = [] } = {} } = await nodeMiddlewareClient.get('/carrier-accounts', {
    params: {
      onlyClicknshipChildAccounts: true,
      pageSize: 100,
      statistics: false,
      ...(status ? { status } : {}),
    },
  });
  return items.map(fromCarrier);
};

export const createClicknshipCarrierAccount = async (parentCarrierId) => {
  const { data } = await carriyoClient.post('/carrier-accounts', {
    parent: {
      parent_id: parentCarrierId,
      parent_tenant_id: CLICKNSHIP_TENANT_ID,
    },
    status: 'ACTIVE',
  });
  return data;
};


export const updateClicknshipCarrierStatus = async (childCarrierId, parentCarrierId, status) => {
  const { data } = await carriyoClient.put(`/carrier-accounts/${childCarrierId}`, {
    parent: {
      parent_id: parentCarrierId,
      parent_tenant_id: CLICKNSHIP_TENANT_ID,
    },
    status,
  });
  return data;
};

export const updateClicknshipTOSConsent = async () => {
  const { data } = await nodeMiddlewareClient.patch('/update-clicknship-tos-consent');
  return data;
};
