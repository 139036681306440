import {
  nodeMiddlewareClient,
} from './base';

export const notifyChange = async (payload) => nodeMiddlewareClient
  .post('/dashboard-notifications', payload);

export const getAppFeedbackState = () => nodeMiddlewareClient
  .get('/application-feedback/state');

export const postAppFeedbackInfo = (data) => nodeMiddlewareClient
  .post('/application-feedback', data);
