import React, { forwardRef } from 'react';

import cls from '../helpers/cls';

import Linear from './Linear';
import CloseFilled from '../icons/CloseFilled';
import './Chip.css';

const Chip = forwardRef((props, ref) => {
  const {
    children,
    className: classes,
    color,
    custom,
    disabled,
    hide,
    margin = 'md',
    onClose,
    variant = 'outlined',
    size = 'md',
    ...rest
  } = props;
  const className = cls('Chip', {
    color, custom, disabled, hide, margin, size, variant,
  }, classes);
  return (
    <Linear
      align="center"
      className={className}
      orientation="horizontal"
      {...rest}
      ref={ref}
    >
      {children}
      {onClose && (
        <button
          className="Chip-close"
          disabled={disabled}
          onClick={onClose}
          type="button"
          aria-label="Delete"
        >
          <CloseFilled />
        </button>
      )}
    </Linear>
  );
});

export default Chip;
