export default [
  {
    id: 'sms-landing',
    path: '/sms',
    to: '/sms/setup',
  },
  {
    id: 'email-landing',
    path: '/email',
    to: '/email/setup',
  },
  {
    id: 'whatsapp-landing',
    path: '/whatsapp',
    to: '/whatsapp/setup',
  },
  {
    id: 'traffic-report-landing',
    path: '/monitor',
    to: '/monitor/traffic',
  },
];
