import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from './IconButton';

const TableAddButton = ({
  children, ...otherProps
}) => (
  <IconButton
    Icon={AddIcon}
    tooltip={children ? undefined : 'Add'}
    noPadding={!children}
    textTransform="lower"
    {...otherProps}
  >
    {children}
  </IconButton>
);

export default TableAddButton;
