import config from '../config';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong! Please try again.';

const getErrorFromBEResponse = (err) => {
  if (!config.isProd) console.error(err);
  return (
    err?.response?.data?.message
    || err?.response?.data?.error
    || err?.response?.data?.errors
    || (typeof err.response?.data === 'string' ? err.response?.data : undefined)
    || err.message
    || err.error
    || DEFAULT_ERROR_MESSAGE
  );
};

export default getErrorFromBEResponse;
