import React, { useLayoutEffect, useRef } from 'react';

import cls from '../helpers/cls';

import Linear from './Linear';

const Collapse = ({
  className,
  children,
  Component = Linear,
  collapsedSize = '0px',
  in: extIsOpen,
  timeout = 300,
  ...rest
}) => {
  const isOpen = Boolean(extIsOpen);

  const ref = useRef(null);
  const element = ref.current;

  useLayoutEffect(() => {
    if (!element) return undefined;
    const state = { timer: undefined };
    if (isOpen) {
      element.style.display = null;
      const showContent = () => {
        element.style.overflow = null;
        element.style.height = 'auto';
      };
      if (timeout) {
        state.timer = setTimeout(showContent, timeout);
      } else {
        showContent();
      }
    } else {
      element.style.overflow = 'hidden';
      const hideContent = () => {
        element.style.display = 'none';
        element.style.height = collapsedSize;
      };
      if (timeout) {
        state.timer = setTimeout(hideContent, timeout);
      } else {
        hideContent();
      }
    }
    element.style.height = `${element.scrollHeight}px`;
    if (!isOpen) {
      requestAnimationFrame(() => requestAnimationFrame(() => {
        element.style.height = collapsedSize;
      }));
    }
    return () => clearTimeout(state.timer);
  }, [isOpen, element, timeout, collapsedSize]);

  return (
    <Component
      {...rest}
      className={cls('Collapse', {}, className)}
      orientation="vertical"
      ref={ref}
      style={{
        ...rest.style,
        transition: `height ${(timeout / 1000).toFixed(2)}s linear`,
      }}
      width="100pr"
    >
      {children}
    </Component>
  );
};

export default Collapse;
