import { get, set } from 'lodash';
import hashFunc from 'fnv1a';
import { getStates } from '../store';

const computeKey = ({
  tenantId,
  carriyoUserId,
  connector,
  namespace,
}) => [
  'sessionStorage.userPref',
  connector === 'global' ? '' : connector, // not adding for auth0 connecter for backward compat
  hashFunc(carriyoUserId),
  tenantId,
  namespace,
].filter(Boolean).join('-');

const deletePref = (namespace, userGlobal) => {
  const {
    auth: {
      tenantId,
      carriyoUserId,
      connector,
    },
  } = getStates();
  const key = computeKey({
    tenantId: userGlobal ? undefined : tenantId,
    carriyoUserId,
    connector,
    namespace,
  }).replace(/^sessionStorage\./, '');
  try {
    window.sessionStorage.removeItem(key);
    return true;
  } catch { // safari private mode
    return false;
  }
};

const fetchPref = (namespace, userGlobal) => {
  const {
    auth: {
      tenantId,
      carriyoUserId,
      connector,
    },
  } = getStates();
  const key = computeKey({
    tenantId: userGlobal ? undefined : tenantId,
    carriyoUserId,
    connector,
    namespace,
  });
  try {
    return JSON.parse(get(window, key));
  } catch (e) {
    // safari private mode
  }
  return undefined;
};

const savePref = (namespace, value, userGlobal) => {
  const {
    auth: {
      tenantId,
      carriyoUserId,
      connector,
    },
  } = getStates();
  const key = computeKey({
    tenantId: userGlobal ? undefined : tenantId,
    carriyoUserId,
    connector,
    namespace,
  });
  try {
    set(
      window,
      key,
      JSON.stringify(value || {}),
    );
  } catch (e) {
    // safari private mode
  }
  return value;
};

export const deleteUserPref = (namespace) => deletePref(namespace, true);
export const deleteUserTenantPref = (namespace) => deletePref(namespace, false);
export const fetchUserPref = (namespace) => fetchPref(namespace, true);
export const fetchUserTenantPref = (namespace) => fetchPref(namespace, false);
export const saveUserPref = (namespace, value) => savePref(namespace, value, true);
export const saveUserTenantPref = (namespace, value) => savePref(namespace, value, false);
