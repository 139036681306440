import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import {
  carriyoClient,
  nodeMiddlewareClient,
} from './base';
import { getStates } from '../store';


export const getTenantDetails = async () => {
  const { auth: { tenantId } } = getStates();
  const { data } = await carriyoClient.get(`/tenant-configs/${tenantId}`);
  return camelCaseKeys(data);
};

export const getTenantSubscription = async () => {
  const { auth: { tenantId } } = getStates();
  try {
    const { data } = await carriyoClient.get(`/subscription/${tenantId}`);
    return camelCaseKeys(data);
  } catch (err) {
    // 404 needs to be handled as empty response
    if (err.response?.status === 404) {
      return null;
    }
    throw err;
  }
};

export const updateTenant = async (tenantSettings) => {
  const { data } = await nodeMiddlewareClient.patch(
    '/tenant',
    snakeCaseKeys(tenantSettings),
  );
  return { data: camelCaseKeys(data) };
};

export const uploadSsoMetadata = (metadata) => nodeMiddlewareClient.post('/tenant/sso-metadata', { metadata });

export const getUserTenants = async (tenantId) => {
  const { data } = await nodeMiddlewareClient.get(
    '/tenants/user-tenants',
    tenantId ? { headers: { 'tenant-id': tenantId } } : {},
  );
  return data;
};

export const updateSubscription = async (subscriptionSettings) => {
  const { data } = await nodeMiddlewareClient.post(
    '/subscription',
    snakeCaseKeys(subscriptionSettings),
  );
  return { data: camelCaseKeys(data) };
};

export const upsertLanguage = async (payload) => {
  const { data } = await nodeMiddlewareClient.patch(
    '/translation-languages',
    payload,
  );
  return { data };
};

export const createTestData = async (payload) => {
  const { data } = await carriyoClient.post(
    '/test-data',
    payload,
  );
  return { data };
};

export const deleteTestData = async () => {
  const { data } = await carriyoClient.delete(
    '/test-data',
  );
  return data;
};
