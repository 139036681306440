import React from 'react';

import { Button } from '@material-ui/core';

import cls from 'lib-frontend-shared/src/helpers/cls';

import Tooltip from './Tooltip';

import './IconButton.scss';

const tooltipize = (component, tooltip) => {
  if (!tooltip) return component;
  return (
    <Tooltip title={tooltip}>
      <span>{component}</span>
    </Tooltip>
  );
};

const IconButton = ({
  Icon,
  children,
  color = 'primary',
  className = '',
  variant = 'standard',
  tooltip,
  size = 'medium',
  iconProps: {
    size: iconSize = 'medium',
    style: iconStyle,
    ...iconProps
  } = {},
  isActive: active = false,
  noPadding = true,
  textTransform = 'upper',
  disabled,
  ...rest
}) => {
  const button = (
    <Button
      color={color}
      variant={variant}
      className={cls('IconButton', {
        active, noPadding, size, textTransform,
      }, className)}
      disabled={disabled}
      {...rest}
    >
      <Icon
        className="IconButton-icon"
        color={color}
        fontSize={iconSize}
        style={{
          padding: '0.5px 0px',
          ...iconStyle,
        }}
        {...iconProps}
      />
      {children}
    </Button>
  );

  return tooltipize(button, tooltip);
};

export default IconButton;
