/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import './SMBField.scss';
import { TextField } from '../../TextFields';

const SMBField = React.forwardRef(({
  className = '',
  inputProps = {},
  InputProps = {},
  ...props
}, ref) => (
  <TextField
    {...props}
    className={`SMBField ${className}`}
    inputProps={{
      ...inputProps,
      className: `SMBField-input ${inputProps.className || ''}`,
    }}
    InputProps={{ ...InputProps, disableUnderline: true }}
    ref={ref}
  />
));

export default SMBField;
