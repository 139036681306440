import React from 'react';

import {
  Warning as WarningIcon,
} from '@material-ui/icons';

import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';

import Tooltip from './Tooltip';

import './Warning.scss';

const Warning = ({
  children,
  issues = [],
  ...rest
}) => (issues.length ? (
  <Tooltip
    title={(
      <Linear orientation="vertical">
        {issues.map((issue) => (
          <Typography key={issue} nowrap>
            {`- ${issue}`}
          </Typography>
        ))}
      </Linear>
    )}
  >
    <Typography className="Warning" color="error" variant="para.sm">
      <Linear align="center" gap="sm" orientation="horizontal">
        <WarningIcon {...rest} color="error" />
        {children}
      </Linear>
    </Typography>
  </Tooltip>
) : null);

export default Warning;
