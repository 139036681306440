import { castArray, get } from 'lodash';
import { getStates, history } from '../store';

const routeStack = {
  previousRouteStack: [],
  hasPreviousPath: false,
};

// to avoid routing back to previous, change the tenant ref to new selected tenant
const getSanitizedStack = (tenantId, stack) => stack.map((url) => {
  if (url === '/') return null;
  if (url.includes('/tenants/')) {
    const urlPartial = url.split('/').slice(3).join('/');
    return `/tenants/${tenantId}${urlPartial ? `/${urlPartial}` : ''}`;
  }
  return url;
}).filter(Boolean);

const getPreviousPathInfo = (explicitStack = [], onlyFetchData = false) => {
  const {
    auth: { tenantId },
    route: {
      breadcrumbs,
    } = {},
  } = getStates();
  if (onlyFetchData) {
    return routeStack;
  }
  const currentPath = get(history, 'location.pathname', '');
  let previousRouteStack = castArray(get(history, 'location.state.from', []));
  if (!previousRouteStack.length && explicitStack.length > 1) {
    previousRouteStack = explicitStack;
  } else if (!previousRouteStack.length) {
    previousRouteStack = [`/tenants/${tenantId}`, ...(breadcrumbs.length > 0 ? breadcrumbs : [])].map((rId) => rId).reverse();
  }
  const sanitizedPreviousRouteStack = getSanitizedStack(tenantId, previousRouteStack);
  const hasPreviousPath = sanitizedPreviousRouteStack.length > 1 || (sanitizedPreviousRouteStack.length === 1 && sanitizedPreviousRouteStack[0] !== currentPath);
  routeStack.previousRouteStack = sanitizedPreviousRouteStack;
  routeStack.hasPreviousPath = hasPreviousPath;
  return {
    previousRouteStack: sanitizedPreviousRouteStack,
    hasPreviousPath,
  };
};
export default getPreviousPathInfo;
