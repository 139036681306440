const client = new window.CrossEventClient(`${window.location.origin}/event-hub.html`);
let connected = false;
let error = false;
const connectPromise = client
  .onConnect()
  .then(() => {
    connected = true;
    return true;
  })
  .catch(() => {
    error = true;
    return false;
  });

/**
 * @param {string} eventName
 * @param {any} data a json serializable data type
 */
export const sendEvent = async (eventName, data) => {
  if (!connected && !error) {
    await connectPromise;
    if (error) return;
  }
  await client.sendEvent(eventName, data);
};

/**
 * @param {string} eventName
 * @param {function} listener
 */
export const listen = (eventName, listener) => {
  client.addEventListener(eventName, listener);
};
