const css = `
input::placeholder {
  color: #b4b4c3;
}
html {
  height: 100%;
}
body {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  background: linear-gradient(139.25deg, #FFFFFF 10.4%, #EFF3FF 32.17%, #FFFFFF 53.5%, #F7F9FF 75.28%, #FFFFFF 95.72%);
}
body .bg-image {
  position: absolute;
  height: 450px;
  width: 450px;
  top: calc(100% - 450px);
  right: 0%;
}
body .auth0-lock-show-password {
  right: 16px !important;
  top: 50% !important;
  transform: translateY(-50%);
}
body .logo {
  position: absolute;
  max-width: 175px;
  height: auto;
  left: 25px;
  top: 25px;
}
body, body .auth0-lock.auth0-lock {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif;
  z-index: 10;
}
/* restyle dialog */
body .auth0-lock.auth0-lock .auth0-lock-widget {
  width: 450px;
  box-shadow: none !important;
}
body .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
  width: 100% !important;
}
body .auth0-lock.auth0-lock .auth0-lock-widget-container {
  margin-top: -120px;
}
body.body--enterpriseLogin .auth0-lock.auth0-lock .auth0-lock-widget {
  min-height: 222px;
}
body .auth0-lock.auth0-lock .auth0-lock-cred-pane {
  background: transparent;
  align-items: center;
}
body .auth0-lock.auth0-lock .auth0-lock-overlay {
  background: radial-gradient(rgb(64 64 75 / 0%), rgb(17 17 24 / 25%)) rgb(34 34 40 / 35%);
}
body .auth0-lock.auth0-lock .auth0-lock-header {
  padding: 0px;
  overflow: visible;
  background: transparent;
  margin: 0;
  margin-bottom: 25px;
}
body .auth0-lock.auth0-lock .auth0-lock-name {
  display: block;
  margin-bottom: 50px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #00063B;
}
body .auth0-lock.auth0-lock .auth0-lock-name[title="Reset your password"] {
  font-size: 36px;
  padding: 0px;
  text-align: left;
  margin-bottom: 0px;
}
/* Move Carriyo logo out from the widget card */
body .auth0-lock.auth0-lock .auth0-lock-header-logo {
  display: none !important;
}

/* restyle form */
body .auth0-lock.auth0-lock .auth0-lock-form {
  padding: 0px;
}
body .auth0-lock.auth0-lock .auth0-lock-input-wrap {
  color: black;
  border-radius: 25px;
  border: none !important;
  padding: 0px !important;
  box-shadow: 0px 0px 9px 2px #11111820 !important;
}
/* body .auth0-lock.auth0-lock .auth0-lock-input-block:not(.auth0-lock-error):focus-within {
  outline: 1px auto #00063B;
} */
body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
  background: #fff;
  color: #00063B;
  height: 50px;
  padding: 0px 20px;
  border-radius: 25px;
}
body .auth0-lock.auth0-lock svg * {
  fill: currentColor !important;
}
body .auth0-lock-error-msg {
  color: #efefef;
  position: relative;
  margin-top: 0;
}
/* make sure error message doesn't shift layout*/
body .auth0-lock-error-invalid-hint {
  position: absolute;
  top: 5px;
  color: #f44336;
}
/* container wrapper */
body .auth0-lock-center {
  display: flex;
  flex-direction: column;
}

body .auth0-lock.auth0-lock .auth0-lock-close-button {
  display: none !important;
}
body .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
  background: none;
  text-transform: none;
  padding: 0;
  margin-top: 20px;
  position: absolute;
  top: 50px;
  width: 100%;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #D80000;
}
/* make sure error message doesn't shift layout*/
/* body .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error > * {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  z-index: 1;
} */
body .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-success {
  background: #00BE9A !important; 
  margin-bottom: 10px !important;
}
/* re-order form and its error message */
body .auth0-lock-content-body-wrapper {
  display: flex;
  flex-direction: column;
}
body .auth0-lock-content-body-wrapper > *:first-child {
  order: 2;
}
body .auth0-lock-content-body-wrapper > *:last-child {
  order: 1;
}
/* fix height of form so that "forgot password" screen doesn't resize */
body .auth0-lock-content-body-wrapper {
  min-height: 150px;
}
body .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-overlay {
  opacity: 0;
}
/* restyle login button */
body .auth0-lock.auth0-lock .auth0-lock-submit {
  background-color: #FB2661 !important;
  height: 55px;
  box-shadow: 1px 1px 10px 3px #11111820 !important;
  border-radius: 35px !important;
  padding: 0px !important;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}
body .auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit {
  height: auto;
  line-height: 1;
}
/* restyle corporate login button */
body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button[data-provider=""],
body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button[data-provider="auth0"] {
  background-color: #00063B;
}
/* Restyle login back button */
body .auth0-lock.auth0-lock .auth0-lock-back-button {
  position: absolute;
  background: none;
  left: -150px;
  top: -80px;
  border: none;
  box-shadow: none;
  width: 15px;
  height: 15px;
}
body .auth0-lock.auth0-lock .auth0-lock-close-button:focus, 
.auth0-lock.auth0-lock .auth0-lock-back-button:focus {
  box-shadow: none !important;
}
body .auth0-lock.auth0-lock .auth0-lock-back-button::after {
  content: 'Go\\a0 Back';
  position: relative;
  top: -7px;
  left: 25px;
}
/* restyle spinner that appears inside of login button after click */
body .auth0-lock.auth0-lock .auth0-loading-container {
  top: -5px;
}
body .auth0-lock.auth0-lock .auth0-lock-submit .auth0-loading {
  position: absolute;
  left: 47%;
  width: 20px;
  height: 20px;
}
/* restyle "login with your corporate credentials" text */
body .auth0-lock.auth0-lock .auth0-lock-form p {
  color: #00063B;
  text-align: left;
}
/* restyle "forgot password" link */
body .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative {
  text-align: left;
  margin-top: -20px !important;
  margin-bottom: 20px !important;
}
body .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link {
  color: #777777;
  font-weight: 500;
}

/* space email and password. give space for floating error message as well */
body .auth0-lock.auth0-lock .auth0-lock-input-block {
  margin-bottom: 40px;
}

.carriyo-altLoginButton {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 11;
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(0,0,0,0.7);
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block !important;
}
.carriyo-altLoginButton[hidden] {
  display: none !important;
}

/* hide header background, sign-up tabs, auth0 badge, submit chevron and logo tint overlay */
body .auth0-lock-header-bg.auth0-lock-blur-support,
body .auth0-lock.auth0-lock .auth0-lock-tabs-container,
body .auth0-lock.auth0-lock .auth0-lock-header-bg .auth0-lock-header-bg-solid,
body .auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit svg,
body .auth0-lock-badge-bottom {
  display: none;
}

/* mobile design */
@media screen and (max-width: 480px) {
  body .auth0-lock.auth0-lock .auth0-lock-header,
  body .auth0-lock.auth0-lock .auth0-lock-cred-pane,
  body .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-body-content {
    background: none;
  }
  /* reset mobile overlay. also no brightness reduction required as image is behind translucent dialog */
  body .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-overlay {
    display: initial;
  }
  body .auth0-lock.auth0-lock .auth0-lock-header-welcome {
    top: -25px;
  }
  body .auth0-lock.auth0-lock .auth0-lock-name {
    margin-bottom: 0px;
    font-size: 32px;
  }
  body .auth0-lock.auth0-lock .auth0-lock-submit {
    height: 50px;
    border-radius: 25px !important;
  }
  body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
    height: 40px;
    border-radius: 15px;
  }
  body.body--stdLogin .auth0-lock-content-body-wrapper {
    position: relative;
  }
  body .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
    top: -65px;
    font-size: 14px;
  }
  /* align form to bottom of screen */
  body .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
    justify-content: center;
  }
  body .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 90% !important;
    padding: 0px 20px;
  }
  body .auth0-lock.auth0-lock .auth0-lock-content-wrapper {
    flex: initial;
    overflow: visible;
  }
  body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
    background: #fff;
  }
  body .bg-image {
    height: 350px;
    width: 310px;
    display: block !important;
    top: calc(100% - 350px);
    right: 0%;
  }
  body .logo {
    position: absolute;
    max-width: 125px;
    height: auto;
    left: 15px;
    top: 15px;
    display: block !important;
  }
}`;

const links = [{
  href: 'https://dev-dashboard.carriyo.com/favicon.svg',
  rel: 'icon',
  type: 'image/svg',
}, {
  href: 'https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap',
}, {
  href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap',
}];

const images = [{
  src: 'https://dev-dashboard.carriyo.com/images/login/carriyo-logo-dark.svg',
  className: 'logo',
}, {
  src: 'https://dev-dashboard.carriyo.com/images/login/bg-login.png',
  className: 'bg-image',
}];

const linkCreator = ({
  href = '',
  rel = 'stylesheet',
  type = '',
}) => {
  const link = document.createElement('link');
  link.type = type;
  link.rel = rel;
  document.head.appendChild(link);
  link.href = href;
};

const imageCreator = ({
  src = '',
  className = '',
}) => {
  const img = document.createElement('img');
  img.className = className;
  img.src = src;
  document.body.appendChild(img);
};


export const appendStyling = () => {
  const style = document.createElement('style');
  document.head.appendChild(style);

  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
};
export const appendImages = () => images.map((param) => imageCreator(param));
export const appendLinks = () => links.map((param) => linkCreator(param));
