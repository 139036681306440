import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';

import { nodeMiddlewareClient } from './base';

export const getAsyncTask = async ({ taskId }, signal) => {
  const { data } = await nodeMiddlewareClient.get(
    `/async-tasks/${encodeURIComponent(taskId)}`,
    { signal },
  );
  return camelCaseKeys(data);
};

export const listAsyncTasks = async (params, signal) => {
  const { data } = await nodeMiddlewareClient.get(
    '/async-tasks',
    { params, signal },
  );
  return camelCaseKeys(data);
};

export const abortAsyncTask = async ({ taskId }, signal) => {
  const { data } = await nodeMiddlewareClient.post(
    `/async-tasks/${encodeURIComponent(taskId)}/abort`,
    {},
    { signal },
  );
  return camelCaseKeys(data);
};

export const dismissAsyncTask = async ({ taskId }, signal) => {
  const { data } = await nodeMiddlewareClient.post(
    `/async-tasks/${encodeURIComponent(taskId)}/dismiss`,
    {},
    { signal },
  );
  return camelCaseKeys(data);
};
