import React, { Fragment } from 'react';
import { map } from 'lodash';

import { Dialog as MuiDialog, IconButton } from '@material-ui/core';

import {
  Close as CloseIcon,
  FiberManualRecord as FiberManualRecordIcon,
  ChevronLeft as ChevronLeftIcon,
  Minimize as MinimizeIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import cls from 'lib-frontend-shared/src/helpers/cls';

import FormButton from 'lib-frontend-shared/src/components/FormButton';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Typography from 'lib-frontend-shared/src/components/Typography';

import Tooltip from './Tooltip';

import './Dialog.scss';

const DialogBase = withStyles(() => ({
  root: {
    '& .MuiPaper-rounded': { borderRadius: 30 },
    '& .MuiBackdrop-root': {
      background: 'linear-gradient(180deg, #00063b80 0%, #06195e80 100%)',
    },
  },
}))(MuiDialog);

const Steps = ({ steps, selected }) => (
  <Linear
    align="center"
    justify="center"
    orientation="horizontal"
    style={{ marginBottom: '14px' }}
    width="100pr"
  >
    {[...Array(steps).keys()].map((index) => (
      <FiberManualRecordIcon
        fontSize="small"
        className={cls('Dialog-step', {
          prev: index < selected,
          next: index > selected,
        })}
      />
    ))}
  </Linear>
);

const desktopPreset = {
  sm: {
    height: '300px',
    width: '500px',
  },
  md: {
    height: '400px',
    width: '600px',
  },
  lg: {
    height: '600px',
    width: '800px',
  },
  xl: {
    height: '600px',
    width: '1024px',
  },
  '2xl': {
    height: '800px',
    width: '1024px',
  },
  xx: {
    height: 'calc(100vh - 8rem)',
    width: 'calc(100vw - 8rem)',
  },
  fullscreen: {
    height: '100vh',
    width: '100vw',
  },
  squareMedium: {
    height: '600px',
    width: '600px',
  },
  squareLarge: {
    height: '800px',
    width: '800px',
  },
};

const mobileWidth = 'calc(100vw - 4rem)';

const mobilePreset = {
  sm: {
    height: '300px',
    width: mobileWidth,
  },
  md: {
    height: '400px',
    width: mobileWidth,
  },
  lg: {
    height: '500px',
    width: mobileWidth,
  },
  xl: {
    height: '600px',
    width: mobileWidth,
  },
  '2xl': {
    height: '800px',
    width: mobileWidth,
  },
  xx: {
    height: 'calc(100vh - 4rem)',
    width: mobileWidth,
  },
  fullscreen: {
    height: '100vh',
    width: '100vw',
  },
};

const Dialog = (props) => {
  const {
    standalone = false,
    noPadding = false,

    actions = [],
    children,
    closeOnBlur = false,
    disableEnforceFocus = false,
    disableCloseButton = false,
    footer = {},
    hideFooter = standalone,
    hideHeader = standalone,
    isMobile,
    onClose = () => {},
    onKeyDown = () => {},
    open,
    size = 'md',
    showClose = true,
    subtitle,
    title,
    toolbar = [],
    type = '',
    selectedStep = 0,
    steps = 0,

    noToolbar,

    onBack = () => {},
    onMinimize,
    showBack = false,
  } = props;

  const { height, width } = isMobile ? mobilePreset[size] : desktopPreset[size];

  return (
    <DialogBase
      disableEnforceFocus={disableEnforceFocus}
      maxWidth="xl"
      open={open}
      onClose={closeOnBlur ? onClose : undefined}
      onKeyDown={onKeyDown}
      classes={{
        paper: cls('Dialog-paper', { size }),
        scrollPaper: cls('Dialog-scrollPaper', { size }),
      }}
    >
      <div
        className={cls('Dialog', { standalone, type })}
        style={{
          height,
          maxHeight: height,
          minHeight: height,
          width,
          maxWidth: width,
          minWidth: width,
        }}
      >
        <Linear
          align="center"
          className={cls('Dialog-toolbar', { standalone, noToolbar })}
          gap="sm"
          justify="space-between"
        >
          {showBack && (selectedStep > 0) && (
            <Linear
              align="center"
              className="Dialog-backButton"
              onClick={() => onBack(selectedStep - 1)}
            >
              <ChevronLeftIcon fontSize="large" />
              <Spacer x="xs" />
              <Typography variant="para.xs" weight="medium" color="secondary">Go Back</Typography>
            </Linear>
          )}
          <Spacer flex />
          {onMinimize && (
            <Linear className={cls('Dialog-headerButtonWrapper', { type })}>
              <IconButton
                className={cls('Dialog-headerButton', { standalone, type })}
                onClick={onMinimize}
              >
                <MinimizeIcon />
              </IconButton>
            </Linear>
          )}
          {showClose && (
            <Linear className={cls('Dialog-headerButtonWrapper', { type })}>
              <IconButton
                className={cls('Dialog-headerButton', { standalone, type })}
                disabled={!showClose || disableCloseButton}
                onClick={onClose}
              >
                {showClose && <CloseIcon />}
              </IconButton>
            </Linear>
          )}
        </Linear>
        <div className={cls('Dialog-container', { standalone, type })}>
          {!hideHeader && (
            <div className={cls('Dialog-header', { type })}>
              <div className={cls('Dialog-titleBar', { type })}>
                {['wizard', 'centered'].includes(type) && <Spacer flex />}
                <Typography className={cls('Dialog-title', { type })} letterSpacing={['wizard', 'centered'].includes(type) ? '3xs' : 'inherit'}>
                  {title}
                </Typography>
                <Spacer flex />
                {toolbar.map(({ component, key, ...rest }) => (component ? (
                  <Fragment key={key}>
                    {component}
                  </Fragment>
                ) : (
                  <IconButton key={key} {...rest} />
                )))}
              </div>
              {subtitle && (
                <Typography variant="para.sm:body">
                  {subtitle}
                </Typography>
              )}
            </div>
          )}
          {type === 'wizard' && Boolean(steps) && (
            <>
              <Spacer y="sm" />
              <Steps steps={steps} selected={selectedStep} />
            </>
          )}
          <div className={cls('Dialog-content', { standalone, noPadding, type })}>
            {children}
          </div>
          {!hideFooter && (
            <div className="Dialog-footer">
              <Linear align="center" gap="lg" orientation="horizontal" width="100pr">
                {footer.left}
              </Linear>
              <Linear align="center" gap="lg" justify="center" orientation="horizontal" width="100pr">
                {map(
                  actions,
                  ({
                    component,
                    'data-testid': testId,
                    disabled,
                    onClick,
                    state,
                    title = '', /* eslint-disable-line no-shadow */
                    tooltip,
                    color = 'primary',
                  }) => {
                    if (component) return component;
                    const isDisabled = state
                      ? state !== 'ready'
                      : Boolean(disabled || tooltip);
                    const button = (
                      <FormButton
                        className="Dialog-action"
                        color={color}
                        data-testid={testId}
                        key={title}
                        state={state || (isDisabled ? 'disabled' : 'ready')}
                        onClick={onClick}
                      >
                        {title}
                      </FormButton>
                    );
                    if (tooltip) {
                      return (
                        <Tooltip key={`${title}-${tooltip}`} title={tooltip}>
                          <span>{button}</span>
                        </Tooltip>
                      );
                    }
                    return button;
                  },
                )}
              </Linear>
              <Linear align="center" gap="lg" orientation="horizontal" width="100pr">
                {footer.right}
              </Linear>
            </div>
          )}
        </div>
      </div>
    </DialogBase>
  );
};

export default Dialog;
