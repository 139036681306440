import { mapValues } from 'lodash';

const trim = (input) => {
  if (typeof input === 'string') return input.trim() || null;
  if (Array.isArray(input)) return input.map((value) => trim(value)).filter(Boolean);
  if (input && typeof input === 'object') return mapValues(input, (value) => trim(value));
  return input;
};

export default trim;
