import {
  castArray,
  debounce,
  filter,
  isEqual,
  map,
  omit,
  uniq,
  xorWith,
} from 'lodash';
import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import { Status } from '../enums';
import { getStates, history, updateLocationsList } from '../store';
import * as apiClient from '../api-client';
import * as toastActions from './toast';
import { reader } from '../helpers/action';
import getLocationListStatesFromURL from '../helpers/getStatesFromURL';
import { push, replace } from './route';
import { fetchUserTenantPref, saveUserTenantPref } from '../helpers/userPrefSessionStorage';
import { fetchNonExistingIds, upsertEntity } from './cached-entities';

export const setState = updateLocationsList;
const sessionStorageKey = 'locationList-v1';

const entity = 'locations';

export const restoreLastLocationListState = () => {
  const { auth: { tenantId }, global: { selectedMerchantIds = [] } } = getStates();
  const {
    queryString = '',
    selectedMerchantIds: lastSavedMerchantIds = [],
  } = fetchUserTenantPref(sessionStorageKey) || {};
  const hasChanged = xorWith(selectedMerchantIds, lastSavedMerchantIds, isEqual).length > 0;
  const params = new URLSearchParams(queryString);
  if (hasChanged) params.delete('page');
  const query = params.toString();
  replace(`/tenants/${tenantId}/locations${query ? `?${query}` : ''}`, false);
};


export const getLocations = reader(
  entity,
  async ({
    allMerchants = false,
    exportCSV,
    maxResults,
  } = {}, signal) => {
    const { global: { selectedMerchantIds, merchantIds } } = getStates();
    const { location } = history;
    const {
      params: {
        page = 0,
        page_size: pageSize = 10,
        search_string: searchString,
        status,
        sort_by: sortBy = 'location_name',
        sort_direction: sortDirection = 'ASC',
      } = {},
      filtersMap = {},
    } = getLocationListStatesFromURL(location, 'locations');
    // eslint-disable-next-line no-param-reassign
    allMerchants = allMerchants || merchantIds.length === selectedMerchantIds.length;
    const results = await apiClient.getLocations({
      page,
      pageSize,
      searchString,
      status,
      allFilters: filtersMap,
      sortBy,
      sortDirection,
      allMerchants,
      exportCSV,
      maxResults,
    }, signal);
    if (!exportCSV) {
      setState(results);
      saveUserTenantPref(sessionStorageKey, {
        ...(fetchUserTenantPref(sessionStorageKey) || {}),
        queryString: location.search,
        selectedMerchantIds,
      });
    }
    return results;
  },
);


export const getLocationOptions = reader(
  entity,
  async ({
    searchString,
    allFilters,
    allMerchants = false,
  } = {}) => {
    const { locations = [] } = await apiClient.getLocations({
      page: 0,
      pageSize: 100,
      searchString,
      status: 'ACTIVE',
      sortBy: 'location_name',
      sortDirection: 'asc',
      allFilters,
      fields: [],
      allMerchants,
    });
    upsertEntity({
      entity,
      list: locations,
      identifier: 'locationId',
    });
    return locations;
  },
);

export const getAllLocationCount = reader(
  entity,
  async () => {
    const { totalLocations } = await apiClient.getLocations({
      page: 0,
      pageSize: 0,
      allMerchants: true,
    });

    setState({
      totalLocationsCount: totalLocations,
    });
    return totalLocations;
  },
);

export const getLocationDetails = reader(
  entity,
  async ({
    locationIds = [],
    locationNames = [],
    allMerchants = true,
  } = {}) => {
    const { cachedEntities: { locations: existingLocations = [] } = {} } = getStates();
    if (!locationIds.length && !locationNames.length) return { locations: existingLocations };
    const castedLocationIds = uniq(castArray(locationIds).filter(Boolean));
    const castedLocationNames = uniq(castArray(locationNames).filter(Boolean));
    if (castedLocationIds.length < 1 && castedLocationNames.length < 1) {
      return { locations: existingLocations };
    }
    const nonExistingIdentifiers = fetchNonExistingIds({
      identifiers: castedLocationIds.length ? castedLocationIds : castedLocationNames,
      entity,
      identifier: castedLocationNames.length ? 'locationName' : 'locationId',
    });
    if (nonExistingIdentifiers.length > 0) {
      const { global: { selectedMerchantIds, merchantIds } } = getStates();
      // eslint-disable-next-line no-param-reassign
      allMerchants = allMerchants || merchantIds.length === selectedMerchantIds.length;
      const { locations = [] } = await apiClient.getLocationDetails({
        ...(castedLocationNames.length ? {
          locationNames: nonExistingIdentifiers,
        } : {
          locationIds: nonExistingIdentifiers,
        }),
        allMerchants,
      });
      const { locations: updatedLocations } = upsertEntity({
        entity,
        list: locations.filter(({ locationId }) => Boolean(locationId)),
        identifier: 'locationId',
      });
      return { locations: updatedLocations };
    }
    const { cachedEntities: { locations = [] } = {} } = getStates();
    return { locations };
  },
);

export const getTotalActiveLocations = reader(
  entity,
  async () => {
    const {
      totalLocations = 0,
    } = await apiClient.getLocations({
      status: Status.active,
      allMerchants: true,
    });
    setState({
      totalActiveLocations: totalLocations,
    });
    return totalLocations;
  },
);

export const setURLState = (payload) => {
  const { auth: { tenantId } } = getStates();
  const { location } = history;

  const {
    nonFilterParams,
    params: oldParams,
  } = getLocationListStatesFromURL(location, 'locations');
  const params = {
    ...oldParams,
    ...payload,
  };

  const filters = Object.keys(omit(params, nonFilterParams));

  const filterParams = filters
    .filter((filterId) => params[filterId] !== undefined)
    .map((filterId) => {
      const filterValue = params[filterId];
      if (Array.isArray(filterValue)) {
        if (filterValue.length === 0) {
          return `${filterId}=[]`;
        }
        return filterValue
          .map((value) => `${filterId}=${encodeURIComponent(value)}`)
          .join('&');
      }
      return `${filterId}=${encodeURIComponent(filterValue)}`;
    })
    .join('&');

  const queryParams = nonFilterParams
    .filter((key) => params[key] !== undefined)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .concat(filterParams ? [filterParams] : [])
    .join('&');
  // set url
  push(`/tenants/${tenantId}/locations?${queryParams}`, false);
};


export const getUserPref = () => {
  // get from sessionStorage
  const userPref = fetchUserTenantPref(sessionStorageKey) || {};
  updateLocationsList({ userPref });
};

export const assignUserPref = (partialPref = {}, shouldUpdateState = true) => {
  const oldUserPref = fetchUserTenantPref(sessionStorageKey) || {};
  const userPref = {
    ...oldUserPref,
    ...partialPref,
  };
  // save to sessionStorage
  saveUserTenantPref(sessionStorageKey, userPref);
  if (shouldUpdateState) {
    updateLocationsList({ userPref });
  }
};

const longDebouncedGetLocations = debounce(getLocations, 700);

export const importBulkLocations = reader(
  'locations',
  async (records) => {
    // split records into chunks of 20 and loop through the requests
    const data = [];
    const chunkSize = 20;
    for (let i = 0; i < records.length; i += chunkSize) {
      const chunk = records.slice(i, i + chunkSize);
      const request = { location_requests: chunk };
      const results = await apiClient
        .importBulkLocations(snakeCaseKeys(request));
      data.push(...results);
    }

    const formattedResults = camelCaseKeys(data).map((item) => {
      if (item.result === 'accepted') {
        return item.location;
      }
      const { locationCode } = item;
      const location = records.find((record) => record.locationCode === locationCode);
      return {
        ...location,
        rejectedReason: item.reason,
      };
    });

    const acceptedLocations = filter(data, { result: 'accepted' });

    if (acceptedLocations.length === data.length) {
      toastActions.success('Import completed.');
    } else {
      toastActions.warning('Import completed with errors.');
    }
    longDebouncedGetLocations();

    return formattedResults;
  },
  { operation: 'import' },
);

export const getLocationStatus = reader(
  'location status',
  async (params) => {
    const { locationCodes = [], locationNames = [] } = params;
    const { locations } = await apiClient.getLocationsByTerm({
      location_code: uniq(locationCodes),
      location_name: uniq(locationNames),
    });
    return locations;
  },
  { operation: 'read' },
);

export const statusGetter = async (locations) => {
  const filteredLocations = locations.filter(({ isValid }) => Boolean(isValid));
  const { data: allLocations } = await getLocationStatus({
    locationNames: map(filteredLocations, 'locationName'),
    locationCodes: map(filteredLocations, 'locationCode'),
  });
  return allLocations;
};

export const locationsToCsv = reader(
  entity,
  apiClient.convertLocationsToCsv,
  { operation: 'export' },
);
