import React from 'react';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import './Tooltip.scss';

const commonProps = {
  borderRadius: '16px',
  maxHeight: '640px',
  maxWidth: '640px',
  padding: '8px',
};

const DarkTooltip = withStyles(() => ({
  tooltip: {
    ...commonProps,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}))(MuiTooltip);

const CardTooltip = withStyles(() => ({
  tooltip: {
    ...commonProps,
    backgroundColor: '#fff',
    color: '#202020',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12)',
  },
}))(MuiTooltip);
const Tooltip = ({
  children, title, variant = 'dark', ...props
}) => {
  const Component = variant === 'card' ? CardTooltip : DarkTooltip;
  return title ? ( // <span>{...}</span> to bypass issues when 'children' is empty/disabled
    (
      <Component
        {...props}
        enterDelay={50}
        enterTouchDelay={100}
        title={title}
        classes={{
          popperInteractive: 'Tooltip-popperInteractive',
        }}
      >
        <span>{children}</span>
      </Component>
    )
  ) : children;
};

export default Tooltip;
