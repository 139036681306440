/* eslint-disable no-nested-ternary */
import { chunk, get } from 'lodash';
import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import {
  carriyoClient,
  nodeMiddlewareClient,

  // utils
  timeZoneOffset,
  toMerchantsParam,
} from './base';
import { getStates } from '../store';
import {
  fromCarrier,
  toCarrier,
  fromCarrierStats,
  fromNetwork,
  toNetwork,
  fromCapacityRule,
  toCapacityRule,
  fromCapacityProfile,
  toCapacityProfile,
  fromCostingProfile,
  toCostingProfile,
  toCostingRule,
  fromCostingRule,
} from '../api-transforms';

export const getCarrierMetadata = () => nodeMiddlewareClient
  .get('/carrier-metadata');

export const getCarrierList = async ({
  page = 0,
  pageSize = 10,
  searchString,
  status,
  allFilters = {},
  sortBy,
  sortDirection,
  aggBy,
  allMerchants = false,
  statistics = true,
  excludeCarrier,
  excludeClicknshipChildAccounts = true,
}, signal) => {
  const {
    global: { selectedMerchantIds },
  } = getStates();
  const {
    merchants = [],
    ...restAllFilters
  } = allFilters;
  const {
    data: {
      pagination: carrierPagination = {},
      items = [],
      [aggBy]: aggregation,
    },
  } = await nodeMiddlewareClient.get('/carrier-accounts', {
    signal,
    params: snakeCaseKeys({
      ...(page ? { page } : {}),
      ...(![null, undefined, ''].includes(pageSize) ? { pageSize } : {}),
      ...toMerchantsParam(allMerchants ? undefined : (merchants.length ? merchants : selectedMerchantIds)),
      ...(status ? { status } : {}),
      ...(searchString ? { searchString } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...((sortBy && sortDirection) ? { sortDirection: sortDirection.toUpperCase() } : {}),
      ...(aggBy ? { aggBy } : {}),
      ...restAllFilters,
      excludeClicknshipChildAccounts,
      ...(excludeCarrier ? { excludeCarrier } : {}),
      ...(statistics ? { statistics } : {}),
      tzoffset: timeZoneOffset,
    }),
  });
  return {
    totalCarriers: get(carrierPagination, 'total'),
    rawCarriers: items,
    carriers: items.map(fromCarrierStats),
    aggregation,
  };
};


export const getCarrierDetails = async ({
  carrierIds = [],
  accountNames = [],
  carrierNames = [],
}) => {
  const carriersToFetch = carrierIds.length ? carrierIds : (accountNames.length ? accountNames : carrierNames);
  const fieldProps = carrierIds.length ? 'carrier_id' : (accountNames.length ? 'account_name' : 'carrier');

  const allRequestedCarriers = await Promise.all(chunk(carriersToFetch, 20).map((identifiers) => nodeMiddlewareClient.get('/frontend/carrier-accounts', {
    params: {
      [fieldProps]: identifiers,
      tzoffset: timeZoneOffset,
    },
  })));
  return {
    carriers: allRequestedCarriers.flatMap(({ data }) => get(data, 'items', [])).map(fromCarrier),
  };
};

export const getCarrierNameList = async ({
  searchString,
  allFilters = {},
  allMerchants = false,
}, signal) => {
  const { global: { selectedMerchantIds } } = getStates();
  const {
    merchants = [],
    ...restAllFilters
  } = allFilters;
  const {
    data,
  } = await nodeMiddlewareClient.get('/frontend/carriers', {
    signal,
    params: {
      ...toMerchantsParam(allMerchants ? undefined : (merchants.length ? merchants : selectedMerchantIds)),
      ...(searchString ? { searchString } : {}),
      ...restAllFilters,
      tzoffset: timeZoneOffset,
    },
  });
  return {
    carrierList: data,
  };
};

export const getCarrierNameDetails = async (carrierNames = []) => {
  const requestedCarrierNames = await Promise.all(chunk(carrierNames, 20).map((identifiers) => nodeMiddlewareClient.get('/frontend/carriers', {
    params: {
      carrier: identifiers,
      tzoffset: timeZoneOffset,
    },
  })));
  return {
    carrierList: requestedCarrierNames.flatMap(({ data }) => data),
  };
};

export const getCarrierById = async (carrierId) => {
  const { data } = await carriyoClient.get(`/carrier-accounts/${carrierId}?statistics=true`);
  return fromCarrierStats(data);
};

export const upsertCarrierSetting = async (body) => {
  const { carrierId } = body;
  const carrier = toCarrier(body);
  if (carrierId) {
    const { data } = await carriyoClient.put(`/carrier-accounts/${carrierId}`, carrier);
    return { data: fromCarrier(data) };
  }
  const { data } = await carriyoClient.post('/carrier-accounts', carrier);
  return { data: fromCarrier(data) };
};

export const deleteCarrier = ({ carrierId }) => carriyoClient
  .delete(`/carrier-accounts/${carrierId}`, { data: {} });

export const generateAccountRequest = async (body) => {
  const { data } = await nodeMiddlewareClient.post('/account-request', body);
  return data;
};

// CARRIER NETWORKS

export const getAllNetworks = async () => {
  const { data } = await carriyoClient.get('/networks');
  return (data || []).map(fromNetwork);
};

export const updateNetwork = async (payload) => {
  const { networkId } = payload;
  const body = toNetwork(payload);
  const { data } = await carriyoClient.put(`/networks/${networkId}`, body);
  return { data: fromNetwork(data) };
};

export const addNetwork = async (payload) => {
  const body = toNetwork(payload);
  const { data } = await carriyoClient.post('/networks', body);
  return { data: fromNetwork(data) };
};

export const assignNetworkToCarriers = async (networkId, carrierList = []) => {
  const { data } = await carriyoClient.post(`/networks/${networkId}/assign`, carrierList);
  return data;
};

export const deleteNetwork = async (networkId) => {
  const { data } = await carriyoClient.delete(`/networks/${networkId}`);
  return data;
};

// CARRIER CAPACITY

export const getCapacityProfiles = async () => {
  const { data } = await carriyoClient.get('/capacities');
  return data.map(fromCapacityProfile);
};

export const createCapacityProfile = async (capacityProfile) => {
  const { data } = await carriyoClient.post('/capacities', toCapacityProfile(capacityProfile));
  return { data: fromCapacityProfile(data) };
};

export const assignCarrierAccountsToProfile = async (profileId, carrierIds = []) => {
  const { data } = await carriyoClient.post(`/capacities/${profileId}/assign`, carrierIds);
  return fromCapacityProfile(data);
};

export const deleteCapacityProfile = async (profileId) => {
  const { data } = await carriyoClient.delete(`/capacities/${profileId}`);
  return data;
};

export const updateCapacityProfile = async (capacityProfile) => {
  const { profileId } = capacityProfile;
  const { data } = await carriyoClient.patch(`/capacities/${profileId}`, toCapacityProfile(capacityProfile));
  return { data: fromCapacityProfile(data) };
};

export const getCapacityProfile = async (profileId) => {
  const { data } = await carriyoClient.get(`/capacities/${profileId}`);
  return fromCapacityProfile(data);
};

export const getCapacityProfileRules = async (profileId) => {
  const { data } = await carriyoClient.get(`/capacities/${profileId}/rules`);
  const alteredData = data.map((selectedData) => fromCapacityRule(selectedData));
  return alteredData;
};

export const createCapacityProfileRules = async (profileId, ruleConfig) => {
  const { data } = await carriyoClient.post(`/capacities/${profileId}/rules`, toCapacityRule(ruleConfig));
  return { data: camelCaseKeys(data) };
};
export const updateCapacityProfileRules = async (profileId, capacityId, ruleConfig) => {
  const { data } = await carriyoClient.put(`/capacities/${profileId}/rules/${capacityId}`, toCapacityRule(ruleConfig));
  return { data: camelCaseKeys(data) };
};

export const deleteCapacityProfileRules = async (profileId, capacityId) => {
  const { data } = await carriyoClient.delete(`/capacities/${profileId}/rules/${capacityId}`);
  return camelCaseKeys(data);
};

export const updateCarrierCapacityRuleSequences = async (profileId, rules) => {
  const body = rules.map(({ capacityId, sequence }) => ({
    rule_id: capacityId,
    sequence,
  }));
  const { data } = await carriyoClient.patch(
    `/capacities/${profileId}/rules/sequences`,
    JSON.stringify(snakeCaseKeys(body)),
    {
      headers: { 'content-type': 'application/json' },
    },
  );
  return data;
};


// SHIPMENT COSTING RULES

export const getCostingProfiles = async () => {
  const { data } = await carriyoClient.get('/costing-rules');
  return data.map(fromCostingProfile);
};

export const createCostingProfile = async ({ costingProfile = {}, costingRules = {} }) => {
  const { data } = await carriyoClient.post('/costing-rules', {
    costing_profile: toCostingProfile(costingProfile),
    costing_rules: costingRules.map(toCostingRule),
  });
  return { data: fromCostingProfile(data) };
};

export const assignCarrierAccountsToCostingProfile = async (profileId, carrierIds = []) => {
  const { data } = await carriyoClient.post(`/costing-rules/${profileId}/assign`, carrierIds);
  return fromCostingProfile(data);
};

export const deleteCostingProfile = async (profileId) => {
  const { data } = await carriyoClient.delete(`/costing-rules/${profileId}`);
  return data;
};

export const updateCostingProfile = async (costingProfile) => {
  const { profileId } = costingProfile;
  const { data } = await carriyoClient.patch(`/costing-rules/${profileId}`, toCostingProfile(costingProfile));
  return { data: fromCostingProfile(data) };
};

export const getCostingProfile = async (profileId) => {
  const { data } = await carriyoClient.get(`/costing-rules/${profileId}`);
  return fromCostingProfile(data);
};

export const getCostingProfileRules = async (profileId) => {
  const { data } = await carriyoClient.get(`/costing-rules/${profileId}/rules`);
  const alteredData = data.map((selectedData) => fromCostingRule(selectedData));
  return alteredData;
};

export const createCostingProfileRules = async (profileId, ruleConfig) => {
  const { data } = await carriyoClient.post(`/costing-rules/${profileId}/rules`, toCostingRule(ruleConfig));
  return camelCaseKeys(data);
};
export const updateCostingProfileRules = async (profileId, ruleId, ruleConfig) => {
  const { data } = await carriyoClient.put(`/costing-rules/${profileId}/rules/${ruleId}`, toCostingRule(ruleConfig));
  return camelCaseKeys(data);
};

export const deleteCostingProfileRules = async (profileId, ruleId) => {
  const { data } = await carriyoClient.delete(`/costing-rules/${profileId}/rules/${ruleId}`);
  return camelCaseKeys(data);
};

export const updateCostingRuleSequences = async (profileId, rules) => {
  const body = rules.map(({ ruleId, sequence }) => ({
    rule_id: ruleId,
    sequence,
  }));
  const { data } = await carriyoClient.patch(
    `/costing-rules/${profileId}/rules/sequences`,
    JSON.stringify(snakeCaseKeys(body)),
    {
      headers: { 'content-type': 'application/json' },
    },
  );
  return data;
};

export const getCarrierCitiesMapping = async ({ carrier }) => carriyoClient
  .get(`/carriermappings/city/${carrier}`);

export const getCarrierStatusMapping = async ({ carrier }) => carriyoClient
  .get(`/carriermappings/status/${carrier}`);

export const getTotalCarrierMappings = async ({ carrier }) => carriyoClient
  .get(`/carriermappings/${carrier}`);
