import React from 'react';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';

import Tooltip from './Tooltip';

const labelPoseParam = {
  top: {
    orientation: 'vertical',
    reverse: false,
  },
  bottom: {
    orientation: 'vertical',
    reverse: true,
  },
  left: {
    orientation: 'horizontal',
    reverse: false,
  },
  right: {
    orientation: 'horizontal',
    reverse: true,
  },
};

export const defaultAnnotationProps = {
  checkbox: {
    labelProps: { position: 'right' },
    descriptionProps: { position: 'top' },
  },
  toggle: {
    labelProps: { position: 'right' },
    descriptionProps: { position: 'top' },
  },
};

const UnifiedLabel = (props) => {
  const {
    children,
    annotationLayoutProps = {},
    description,
    descriptionProps: extDescriptionProps = {},
    label,
    tooltip,
    labelProps: extLabelProps = {},
    width,
    helpMessage,
    ...params
  } = props;
  const { disabled, id, required } = params;
  const {
    position: descriptionPosition = 'top', ...descriptionProps
  } = extDescriptionProps;
  const {
    position: labelPosition = 'top',
    ...restLabelProps
  } = extLabelProps;
  const descriptionPartial = description && (
    <Typography disabled={disabled} {...descriptionProps} variant="para.sm:body">
      {description}
    </Typography>
  );
  const labelParam = labelPoseParam[labelPosition];
  const {
    gap: labelGap = (
      labelParam.orientation === 'horizontal' ? 'lg' : 'sm'
    ),
    ...labelProps
  } = restLabelProps;
  const content = label ? (
    <Linear gap="2xs" orientation="vertical" {...annotationLayoutProps} width={width}>
      <Linear
        align={(
          labelParam.orientation === 'horizontal'
          && !(description && descriptionPosition === 'top')
        ) ? 'center' : 'start'}
        justify={labelParam.reverse ? 'end' : 'start'}
        gap={labelGap}
        {...labelParam}
        width="100pr"
      >
        <Linear gap="sm" orientation="vertical" width={labelPosition === 'left' ? undefined : '100pr'}>
          <Typography disabled={disabled} nowrap {...labelProps} variant={`para.sm${description ? '' : ':body'}`}>
            <label htmlFor={id}>
              {label}
              {required ? '*' : ''}
              {helpMessage ? (
                <Tooltip style={{ marginLeft: '5px' }} title={helpMessage}>
                  <HelpOutlineIcon style={{ width: '13px', height: '13px' }} />
                </Tooltip>
              ) : ''}
            </label>
          </Typography>
          {descriptionPosition === 'top' && descriptionPartial}
        </Linear>
        {children}
      </Linear>
      {descriptionPosition === 'bottom' && descriptionPartial}
    </Linear>
  ) : children;

  return tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content;
};

export default UnifiedLabel;
