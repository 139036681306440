/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cls from '../helpers/cls';
import './Switch.css';

const Switch = (props) => {
  const {
    className: classes,
    color = 'primary',
    checked = false,
    margin = false,
    variant = 'filled',
    noPropogation = false,
    ...rest
  } = props;

  const className = cls('Switch', { margin }, classes);
  const contentClass = cls('Switch-content', { variant });
  const inputClasses = cls('Switch-input', { color, variant });

  return (
    <div className={className}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={contentClass}
        onClick={noPropogation ? (event) => event.stopPropagation() : undefined}
      >
        <input
          {...rest}
          checked={checked}
          className={inputClasses}
          type="checkbox"
          value={checked}
        />
        <div className="Switch-track" />
        <div className="Switch-thumb" />
      </div>
    </div>
  );
};

export default Switch;
