import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  castArray,
  difference,
  find,
  flatMap,
  get,
  isEqual,
  keyBy,
  partition,
  pick,
  set,
  uniqBy,
} from 'lodash';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Chip,
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDownIcon,
} from '@material-ui/icons';
import cls from 'lib-frontend-shared/src/helpers/cls';
import naturalSort from 'lib-frontend-shared/src/helpers/naturalSort';
import pluralize from 'lib-frontend-shared/src/helpers/pluralize';
import sanitizeTextForRegex from 'lib-frontend-shared/src/helpers/sanitizeTextForRegex';
import textSearcher from 'lib-frontend-shared/src/helpers/textSearcher';
import useDeepMemo from 'lib-frontend-shared/src/helpers/useDeepMemo';

import CustomChip from 'lib-frontend-shared/src/components/Chip';
import Divider from 'lib-frontend-shared/src/components/Divider';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Typography from 'lib-frontend-shared/src/components/Typography';

import { isOptionWithValueAny, isValueAny, makeOptionWithValueAny } from '../enums';

import { AutocompleteTextField as TextField } from './TextFields';
import Tooltip from './Tooltip';
import Warning from './Warning';

import './MaterialAutocomplete.scss';

const flattenAutocompleteOptions = (options) => {
  if (!options) return [];
  // flatten grouped options
  if (options[0]?.options) {
    return flatMap(options, (group) => group.options.concat({
      type: 'divider',
      disabled: true,
    })).slice(0, -1);
  }
  return options;
};

export const filterAndSortOptions = (options, searchText, additionalFieldsToSearch = []) => {
  const baseField = 'label';
  const fieldsToSearchBy = [baseField, ...additionalFieldsToSearch];
  const sanitizedSearchText = sanitizeTextForRegex(searchText);
  const getSearchRank = textSearcher(sanitizedSearchText);
  const enrichedFilteredOptions = options.map((option) => {
    const searchRanks = fieldsToSearchBy.map((field) => getSearchRank(option[field]));
    return [option, Math.max(...searchRanks)];
  }).filter(([, rank]) => rank);

  return enrichedFilteredOptions
    .sort(naturalSort.by(baseField)) // always sort by baseField first
    .sort((x, y) => y[1] - x[1])
    .map(([option]) => option);
};

export const getParamsForHandlerWithAllOption = ({
  allowAll,
  allOptionLabel,
  multiple,
  onChange: defaultOnChange,
  options,
  useLazyLoadOptions,
  value,
}) => {
  const allOption = makeOptionWithValueAny(allOptionLabel);
  const onlyShowValueAny = multiple && value?.some(isValueAny);
  const onChange = !allowAll || !multiple
    ? defaultOnChange
    : (selection, ...rest) => defaultOnChange(
      selection.some(isOptionWithValueAny) ? [allOption] : selection,
      ...rest,
    );
  return {
    onChange,
    useLazyLoadOptions: !onlyShowValueAny && useLazyLoadOptions,
    options: [
      ...allowAll ? [allOption] : [],
      ...onlyShowValueAny ? [] : options,
    ],
    value,
  };
};

const setDefault = (object, path, defaultValue) => {
  if (get(object, path) === undefined) {
    set(object, path, defaultValue);
  }
};

const fetchOptionValue = (options, value, objectType) => options.find((entry) => isEqual(objectType ? pick(entry, Object.keys(value)) : entry.value, value));
const findOption = (options, value, objectType) => {
  if (Array.isArray(value)) {
    return value.map((val) => fetchOptionValue(options, val, objectType)).filter(Boolean);
  }
  return fetchOptionValue(options, value, objectType);
};

const DefaultChip = ({
  className, // unused
  chipTooltipTitle,
  customChipProps,
  highlightCustomValues,
  isACustomValue,
  label,
  onDelete,
  options,
  value,
  variant = 'filled',
  ...rest
}) => {
  const customChip = customChipProps.find(
    ({ enums = [] }) => enums.includes(value?.toLowerCase()),
  );
  const { tooltip: customTooltip, ...customStyle } = customChip || {};
  const chip = (
    <CustomChip
      {...rest}
      color={variant === 'outlined' ? 'default' : 'tertiary'}
      custom={isACustomValue && highlightCustomValues && options.length > 0}
      onClose={onDelete}
      style={customStyle}
      variant={variant === 'outlined' ? 'outlined' : 'filled'}
    >
      <Typography>
        {label}
      </Typography>
      <Spacer x="sm" />
    </CustomChip>
  );
  const tooltip = isACustomValue && highlightCustomValues
    ? chipTooltipTitle.replace('%label', label)
    : customTooltip;
  return tooltip ? (
    <Tooltip title={tooltip}>
      {chip}
    </Tooltip>
  ) : chip;
};

const undefinedOrNullValues = [undefined, null, false];

const defaultPopupIcon = <ArrowDownIcon />;

const MaterialAutocomplete = (props) => {
  const {
    anyCase = false,
    options: optionsProp = [],
    className = '',
    freeSolo = true,
    highlightCustomValues = true,
    clearable = true,
    disabled = false,
    variant = 'standard',
    isNewDesign,
    isValueOfObjectType = false,
    onChange = () => {},
    popupIcon = defaultPopupIcon,
    forcePopupIcon = 'auto',
    placeholder,
    RenderComponent,
    ChipRenderer = isNewDesign || variant === 'outlined' ? DefaultChip : undefined,
    customOptionProps: {
      chipTooltipTitle = '%label is a custom option',
      customChipProps = [],
    } = {},
    InputComponent = TextField,
    onInputChange: onParentInputChange = () => {},
    useLazyLoadOptions = false,
    extraFilteringEnums = [],
    style = {},
    textFieldProps = {},
    validate = true,
    width = isNewDesign ? 'full' : 'auto',
    multiple = false,
    transformLabel = ({ label }) => label,
    transformValue = ({ value }) => value,
    value: rawValue = multiple ? [] : '',
    isLoading = false,
    loadingText = '',
    noOptionsText,
    clearTextOnChange = false,
    ...partialProps
  } = props;
  const flattenedOptions = flattenAutocompleteOptions(optionsProp);
  // options need better change-detection so as to prevent unnecessary re-renders
  const [optionsWithIssue, options] = useDeepMemo(partition(
    flattenedOptions,
    ({ issue }) => !undefinedOrNullValues.includes(issue),
  ));

  const issues = useMemo(() => (freeSolo ? [] : castArray(rawValue).filter((value) => (
    value === '' ? false : !findOption(options, value, isValueOfObjectType)
  )).map((value) => {
    const match = findOption(optionsWithIssue, value, isValueOfObjectType);
    return match ? `${match.label}${match.issue ? ` (${match.issue})` : ''}` : JSON.stringify(value);
  })), [options, optionsWithIssue, rawValue]);

  const invalid = Boolean(issues.length);

  const { required = invalid, ...rest } = partialProps;

  const fallback = findOption(flattenedOptions, rawValue, isValueOfObjectType) || [{ value: '' }];
  // eslint-disable-next-line no-nested-ternary
  const value = !invalid ? rawValue : (!multiple ? '' : (
    isValueOfObjectType ? fallback : fallback.map(({ value: val }) => val)
  ));
  // deep defaults
  // Note: can't use _.merge or _.cloneDeep as it messes with react refs in the props.
  setDefault(textFieldProps, 'style.width', '100%');

  const internalRef = useRef();
  const externalRef = textFieldProps.ref;
  const ref = externalRef || internalRef;

  const [searchText, setSearchText] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : { label: '', value: '' });

  const noSelection = multiple ? !selectedOptions?.length : !selectedOptions?.value;

  const trimAndSetAutoComplete = (allOptions) => {
    let newOptions = allOptions;
    if (useLazyLoadOptions) {
      newOptions = [
        ...newOptions,
        { label: '...type to load more options', disabled: true, value: '' },
      ];
    } else if (newOptions.length > 101) {
      newOptions = [
        ...newOptions.slice(0, 100),
        { label: '...type to show more options', disabled: true, value: '' },
      ];
    }
    setAutocompleteOptions(newOptions);
  };

  useEffect(() => {
    let newSelectedOptions = multiple ? [] : { label: '', value: '' }; // default option
    let fieldValue = value;
    // #region - setting values
    if (fieldValue !== undefined) {
      if (multiple) {
        // 'multiple' option needs value to be an array
        // of strings or objects (with value & optional label)
        fieldValue = castArray(fieldValue);
        if (fieldValue.length) {
          const optionsLookUp = keyBy(options, 'value');
          newSelectedOptions = fieldValue.map((item) => {
            const optionValue = (typeof item === 'object' ? item.value : item) || '';
            const label = (typeof item === 'object' ? item.label : item) || optionValue;

            // find existing options
            if (optionsLookUp[optionValue]) return optionsLookUp[optionValue];
            return { label, value: optionValue, isACustomValue: true };
          });
        }
      } else {
        if (isValueOfObjectType) fieldValue = get(value, 'value', '');
        // if none of the available options is selected
        newSelectedOptions = options.find(({ value: optionValue = '' }) => fieldValue === optionValue) || {
          label: fieldValue,
          value: fieldValue,
        };
      }
    }
    if (clearTextOnChange) {
      setSearchText('');
    }
    trimAndSetAutoComplete(
      difference(
        filterAndSortOptions(options, searchText, extraFilteringEnums),
        newSelectedOptions,
      ),
    );
    setSelectedOptions(newSelectedOptions);
    // #endregion
  }, [options, JSON.stringify(value)]);

  const getInputEl = () => ref?.current?.querySelector('input[type="text"]');

  const clearValidation = () => {
    const inputEl = getInputEl();
    if (inputEl) inputEl.setCustomValidity('');
  };

  const checkValidation = (autocompleteValue) => {
    if (!validate) return;
    // find input and remove invalidation
    const inputEl = getInputEl();
    if (!inputEl) return;
    if (invalid) {
      inputEl.setCustomValidity(`Unsupported ${
        pluralize('value', issues.length)
      }: ${issues.join(', ')}`);
    } else if (required) {
      if (autocompleteValue) {
        inputEl.setCustomValidity('');
      } else {
        inputEl.setCustomValidity('Please fill out this field');
      }
    }
  };

  const onOptionSelect = (event, selected) => {
    let autocompleteValue;
    if (multiple) {
      // selected is an array
      autocompleteValue = selected?.length ? uniqBy(selected, 'value') : [];
      // transform values to uppercase and use appropriate label
      autocompleteValue = autocompleteValue.map((option) => {
        const {
          useValueAsLabel = false,
          isACustomValue = false,
          value: mappedValue,
          label,
        } = option;
        if (isACustomValue) {
          return {
            value: anyCase ? mappedValue : mappedValue.toUpperCase(),
            label: useValueAsLabel ? mappedValue : label,
          };
        }
        return option;
      });
      checkValidation(autocompleteValue.length);
    } else {
      autocompleteValue = get(selected, 'value');
      checkValidation(autocompleteValue);
    }
    setSearchText('');
    return onChange(autocompleteValue);
  };

  // limit search feature to fewer entries
  const onInputChange = async (event, text, reason) => {
    // this event fires during initialization which causes an infinite loop of setState() calls
    if (!event) {
      return;
    }
    setSearchText(text);
    await onParentInputChange(text);
    if (!text || reason === 'reset') {
      trimAndSetAutoComplete(options);
      return;
    }
    let newOptions = [];
    if (multiple) {
      newOptions = filterAndSortOptions(
        difference(options, selectedOptions),
        text,
        extraFilteringEnums,
      );
      if (!newOptions.length && freeSolo) {
        newOptions = [{
          value: text,
          label: `Add "${text}"`,
          // flag to know that this is a custom value
          isACustomValue: true,
          // flag to know when to use label and
          // when to use uppercased value
          useValueAsLabel: true,
        }];
      }
    } else {
      checkValidation(text);
      newOptions = filterAndSortOptions(options, text, extraFilteringEnums);
    }

    trimAndSetAutoComplete(newOptions);
  };

  // for free text, single select accept text as value on blur
  const onBlur = (!freeSolo || multiple) ? clearValidation : (event) => {
    const text = event.target.value;
    if (text || clearable) {
      // this reduce is super slow for some reason. so mutate accumulator
      const optionsByPhrase = options.reduce((acc, item) => {
        if (item.label && item.value) {
          acc[item.label.toLowerCase().trim()] = item;
          acc[item.value.toLowerCase()] = item;
        }
        return acc;
      }, {});

      const option = optionsByPhrase[text.toLowerCase().trim()] || {
        label: text.trim(),
        value: text.trim(),
        isACustomValue: true,
      };

      onChange(option.value);
    }
  };

  const renderTags = !multiple ? undefined : (tagValues, getTagProps) => {
    if (ChipRenderer) {
      return tagValues.map((tag, index) => (
        <ChipRenderer
          {...tag}
          {...getTagProps({ index })}
          chipTooltipTitle={chipTooltipTitle}
          customChipProps={customChipProps}
          highlightCustomValues={highlightCustomValues}
          options={options}
          variant={variant}
        />
      ));
    }
    const theme = createMuiTheme({
      palette: {
        secondary: {
          main: '#FCC6B1',
          contrastText: '#ED4F4F',
        },
      },
      typography: {
        fontFamily: '"Inter", "Noto Naskh Arabic", "Arial", sans-serif',
        fontSize: 12,
      },
      overrides: {
        MuiChip: {
          root: {
            height: '25px',
          },
        },
      },
    });

    return (
      <ThemeProvider theme={theme}>
        {tagValues.map(({ label, value: chipValue, isACustomValue = false }, index) => {
          const { key, ...restOfTheTagProps } = getTagProps({ index });

          const customChip = find(customChipProps, ({
            enums = [],
          }) => enums.includes(chipValue?.toLowerCase()));

          if (isACustomValue && highlightCustomValues) {
            return (
              <Tooltip key={key} title={chipTooltipTitle.replace('%label', label)}>
                <Chip
                  color={options.length === 0 ? undefined : 'secondary'}
                  label={label}
                  {...restOfTheTagProps}
                />
              </Tooltip>
            );
          }

          if (customChip) {
            const { tooltip, ...customStyle } = customChip;

            const chip = (
              <Chip
                classes={{ deleteIcon: 'MaterialAutocomplete-deleteIcon' }}
                label={label}
                style={customStyle}
                {...getTagProps({ index })}
              />
            );

            return tooltip ? (
              <Tooltip key={key} title={tooltip}>
                {chip}
              </Tooltip>
            ) : (
              chip
            );
          }

          return <Chip label={label} {...getTagProps({ index })} />;
        })}
      </ThemeProvider>
    );
  };

  if (multiple) {
    textFieldProps.onPaste = (event) => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData('Text');
      const pastedPhrases = pastedText.split(/[\n,]/g);

      // this reduce is super slow for some reason. so mutate accumulator
      const optionsByPhrase = options.reduce((acc, item) => {
        acc[item.label.toLowerCase()] = item;
        acc[item.value.toLowerCase()] = item;
        return acc;
      }, {});

      const convertedOptions = uniqBy(
        [
          ...selectedOptions,
          ...pastedPhrases
            .filter((text) => text.trim())
            .map(
              (text) => optionsByPhrase[text.toLowerCase().trim()] || ({
                label: text.trim(),
                value: text.trim(),
                isACustomValue: true,
              }),
            ),
        ],
        'value',
      );
      onChange(convertedOptions);
    };
  }

  const autocompleteClasses = makeStyles({
    inputRoot: {
      minHeight: '28px', // keep in sync with MaterialReactSelect (in multiselect mode)
    },
    endAdornment: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    clearIndicator: {
      marginRight: '0',
    },
    popupIndicator: {
      marginRight: '0',
    },
  })();

  const autocompleteComponentWidth = { full: '100%', long: '250px', short: '175px' };
  return (
    <Autocomplete
      autoHighlight
      className={cls('MaterialAutocomplete', { isNewDesign, variant }, className)}
      style={{
        width: autocompleteComponentWidth[width] || width,
        ...style,
      }}
      classes={{
        ...autocompleteClasses,
        paper: cls('MaterialAutocomplete-optionPaper', { isNewDesign }),
        listbox: cls('MaterialAutocomplete-optionList', { isNewDesign }),
      }}
      freeSolo={freeSolo}
      disabled={disabled}
      multiple={multiple}
      options={autocompleteOptions}
      getOptionLabel={({ label: optionLabel = '' } = {}) => optionLabel}
      getOptionSelected={({ value: autocompleteValue = '' } = {}, selectedValue = '') => autocompleteValue === selectedValue}
      getOptionDisabled={({ disabled: isOptionDisabled }) => isOptionDisabled}
      onInputChange={onInputChange}
      loading={isLoading}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      onBlur={onBlur}
      forcePopupIcon={forcePopupIcon}
      popupIcon={popupIcon}
      renderTags={renderTags}
      disableClearable={!clearable}
      value={selectedOptions}
      // MUI is not filtering out all the results if we try to search based on
      // the non-label enum. This func. should place here to get the right results.
      filterOptions={(allOptions) => allOptions}
      renderInput={(params) => {
        const content = (
          <InputComponent
            {...rest}
            {...textFieldProps}
            {...params}
            ref={ref}
            variant={variant}
            placeholder={noSelection ? placeholder : undefined}
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {invalid ? (
                    <Warning
                      className="MaterialAutocomplete-warningIcon"
                      issues={issues.map(
                        (issue) => `Unsupported value: ${issue}`,
                      )}
                    />
                  ) : null}
                  {params?.InputProps?.endAdornment}
                </>
              ),
            }}
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
            inputProps={{
              ...params.inputProps,
              value: transformValue({ value: useLazyLoadOptions ? (searchText || get(params, 'inputProps.value')) : get(params, 'inputProps.value'), selectedOptions, rawValue }),
              ...textFieldProps.InputProps?.inputProps,
              ...textFieldProps.inputProps,
              autoComplete: 'disabled', // disable autocomplete and autofill
              required,
            }}
          />
        );
        const { inputProps: { tooltip } = {} } = textFieldProps;
        return (
          tooltip ? (
            <Tooltip title={tooltip}>
              {content}
            </Tooltip>
          ) : content
        );
      }}
      renderOption={({
        type,
        label = '',
        value: optionVal,
        ...restProps
      }) => {
        if (type === 'divider') return <Divider />;
        const content = RenderComponent
          ? <RenderComponent {...restProps} label={label} value={optionVal} />
          // TODO: Deprecate transformLabel function parameter
          // and use RenderComponent (just as MUI takes in other props)
          : transformLabel({ ...restProps, label, value: optionVal });
        return (
          <div data-test-locator="autocomplete-option" disabled={restProps.disabled} label={label} value={optionVal}>
            {content}
          </div>
        );
      }}
      onChange={onOptionSelect}
    />
  );
};

export default MaterialAutocomplete;
