import React from 'react';
import { isEmpty } from 'lodash';
import blockEvent from '../helpers/blockEvent';
import cls from '../helpers/cls';
import Typography from './Typography';

import './Link.css';

const linkAction = (action) => (event) => {
  blockEvent(event);
  return action(event);
};

const actionGetter = (push) => ({
  href, newTab, onClick,
}) => {
  if (!(href || onClick)) return {};
  const base = { href };
  if (onClick) return { ...base, onClick: linkAction(onClick) };
  const sameSite = (
    href.startsWith('/')
    || href.startsWith(window.location.origin)
  );
  if (sameSite && !newTab) {
    return {
      ...base,
      onClick: linkAction(() => push(href)),
    };
  }
  return {
    ...base,
    rel: 'noopener noreferrer',
    target: !sameSite || newTab ? '_blank' : undefined,
  };
};

const baseProps = { color: 'link', inline: true };

const Link = (props) => {
  const {
    active = false,
    children,
    getAction,
    hoverEffect = 'underline',
    href,
    newTab = false,
    onClick,
    title,
    ...overrides
  } = props;
  const action = getAction({ href, newTab, onClick });
  const disabled = isEmpty(action);
  const className = cls('Link', { active, disabled, hoverEffect });
  return (
    <Typography {...baseProps} {...overrides} disabled={disabled}>
      <a {...action} className={className} data-native title={title}>
        {children}
      </a>
    </Typography>
  );
};

export default (push) => {
  const getAction = actionGetter(push);
  return (props) => <Link {...props} getAction={getAction} />;
};
