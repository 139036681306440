import delay from 'delay';
import { get } from 'lodash';
import pluralize from 'lib-frontend-shared/src/helpers/pluralize';
import { getStates, updateClicknShipService } from '../store';
import * as apiClient from '../api-client';
import { reader, writer } from '../helpers/action';
import { CLICKNSHIP_TENANT_ID } from '../enums';

export const setState = updateClicknShipService;

const singularEntity = 'clicknship-service';
const pluralEntities = pluralize(singularEntity);

export const isClicknshipCarrierAccount = (carrierInfo) => Boolean(get(carrierInfo, 'parent.parentId')) && get(carrierInfo, 'parent.parentTenantId') === CLICKNSHIP_TENANT_ID;

export const getAllClicknShipServices = reader(
  pluralEntities,
  async () => {
    const [result = [], data = []] = await Promise.all([
      apiClient.getAllClicknShipServices(),
      apiClient.getAllServiceAssociatedCarriers(),
    ]);
    const statusPerCarrier = data.reduce((acc, {
      carrierId, parent, parent: { parentId } = {}, status,
    }) => {
      if (isClicknshipCarrierAccount({ parent })) {
        acc[parentId] = {
          status,
          childCarrierId: carrierId,
        };
      }
      return acc;
    }, {});

    const allServices = result.map((service) => ({
      ...service,
      ...(statusPerCarrier[service.carrierAccountId] || { status: 'INACTIVE' }),
    }));
    setState({
      allServices,
      actionCallTriggered: true,
    });
    return allServices;
  },
);


export const getAllCachedClicknShipServices = reader(
  pluralEntities,
  async () => {
    const {
      clicknShipService: {
        allServices: allClicknshipServices,
        actionCallTriggered = false,
      },
    } = getStates();
    if (actionCallTriggered) {
      return allClicknshipServices;
    }
    return getAllClicknShipServices();
  },
);

export const getClicknShipServiceById = reader(
  singularEntity,
  async (serviceId) => {
    const data = await apiClient.getClicknShipServiceById(serviceId);
    return data;
  },
);

export const createClicknshipCarrierAccount = writer(
  singularEntity,
  async (carrierAccountId, status) => {
    const { data } = await apiClient.createClicknshipCarrierAccount(carrierAccountId);
    const {
      clicknShipService: {
        allServices = [],
      },
    } = getStates();
    const updatedServices = allServices.map(
      (service) => (service?.carrierAccountId === carrierAccountId ? ({ ...service, status }) : service),
    );
    setState({
      allServices: updatedServices,
    });
    return data;
  },
);

export const updateClicknshipCarrierStatus = writer(
  singularEntity,
  async (childCarrierId, carrierAccountId, status) => {
    const { data } = await apiClient.updateClicknshipCarrierStatus(childCarrierId, carrierAccountId, status);
    const {
      clicknShipService: {
        allServices = [],
      },
    } = getStates();
    const updatedServices = allServices.map(
      (service) => (service?.carrierAccountId === carrierAccountId ? ({ ...service, status }) : service),
    );
    setState({
      allServices: updatedServices,
    });
    return data;
  },
);


export const fetchUpdatedCarrierAccounts = reader(
  singularEntity,
  async ({ delayInSec = 0 }) => {
    await delay(delayInSec);
    getAllClicknShipServices();
    return {};
  },
);

export const updateClicknshipTOSConsent = writer(
  singularEntity,
  apiClient.updateClicknshipTOSConsent,
  {
    onSuccess: null,
  },
);
