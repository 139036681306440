export default {
  get(prop) {
    try {
      return window.sessionStorage[prop];
    } catch (e) {
      // safari private mode
      return undefined;
    }
  },

  set(prop, value) {
    try {
      window.sessionStorage[prop] = value;
      return true;
    } catch (e) {
      // safari private mode
      return false;
    }
  },

  delete(prop) {
    try {
      delete window.sessionStorage[prop];
      return true;
    } catch (e) {
      // safari private mode
      return false;
    }
  },
};
