import CircleIcon from './Circle';
import CheckCircleIcon from './CheckCircle';
import CheckSquareIcon from './CheckSquare';
import CloseFilledIcon from './CloseFilled';
import DashCircleIcon from './DashCircle';
import DownChevronIcon from './DownChevron';
import DashSquareIcon from './DashSquare';
import FadedCheckCircleIcon from './FadedCheckCircle';
import SquareIcon from './Square';

export const Circle = CircleIcon;
export const CheckCircle = CheckCircleIcon;
export const CheckSquare = CheckSquareIcon;
export const CloseFilled = CloseFilledIcon;
export const DashCircle = DashCircleIcon;
export const DownChevron = DownChevronIcon;
export const DashSquare = DashSquareIcon;
export const FadedCheckCircle = FadedCheckCircleIcon;
export const Square = SquareIcon;
