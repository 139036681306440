/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import JsonView from 'react-json-view';
import {
  isEmpty,
  isEqual,
  kebabCase,
  startCase,
} from 'lodash';
import delay from 'delay';

import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  CheckCircle as CheckedIcon,
  Delete as DeleteIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

import Button from 'lib-frontend-shared/src/components/Button';
import Card from 'lib-frontend-shared/src/components/Card';
import CircularProgress from 'lib-frontend-shared/src/components/CircularProgress';
import Collapse from 'lib-frontend-shared/src/components/Collapse';
import FormButton from 'lib-frontend-shared/src/components/FormButton';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Radio from 'lib-frontend-shared/src/components/Radio';
import Typography from 'lib-frontend-shared/src/components/Typography';

import sentenceCase from 'lib-frontend-shared/src/helpers/sentenceCase';
import useAsyncState from '../../helpers/useAsyncState';

import config from '../../config';

import {
  abortAsyncTask,
  dismissAsyncTask,
  getAsyncTask,
  updateAsyncTasks,
} from '../../actions/async-task';

import Dialog from '../Dialog';
import Link from '../Link';
import { makeProgressStepper, makeProgressDialog } from '../ProgressDialog';
import Table from '../Table';
import Tooltip from '../Tooltip';
import Menu from '../Menu';
import TableAddButton from '../TableAddButton';
import { makeUnifiedInput } from '../UnifiedInput';

export const generalSettingsTitle = 'Setup Settings';

const { backendBaseUrl } = config;

const UnifiedInput = makeUnifiedInput({
  mapping: {
    clearable: true,
    type: 'select',
  },
  textKey: {
    required: true,
    style: { width: '100%' },
    type: 'text',
  },
});

export const WebhookInfo = ({
  connectorWebhookUrlPrefix,
  event,
  eventLabel,
  index,
  optional,
  route = event,

  connectorId,
  tenantId,
  webhookApiVersion,

  contentGap = 'xs',
  contentVariant = 'para.sm',
  headingVariant = 'para.sm',
}) => {
  const entries = [
    { label: 'Event:', value: eventLabel || sentenceCase(event) },
    { label: 'Format:', value: 'JSON' },
    { label: 'URL:', value: `${backendBaseUrl}/${connectorWebhookUrlPrefix}/${route}?tenantId=${tenantId}&connectorId=${connectorId}` },
    { label: 'Webhook API version:', value: webhookApiVersion },
  ];
  return (
    <Linear gap={contentGap} key={event} orientation="vertical" width="100pr">
      <Typography variant={headingVariant}>
        {`Webhook ${index + 1}:${optional ? ' (Optional)' : ''}`}
      </Typography>
      <div />
      {entries.map(({ label, value }) => (
        <Linear align="start" gap="sm" key={`${event}-${label}`} widht="100pr">
          <Typography nowrap variant={`${contentVariant}:body`}>
            {label}
          </Typography>
          <Typography variant={contentVariant} style={{ wordBreak: 'break-all' }}>
            {value}
          </Typography>
        </Linear>
      ))}
    </Linear>
  );
};

export const WebhookSetupGuide = ({
  connector,
  connectorId,
  connectorWebhookUrlPrefix,
  events,
  tenantId,
  webhookApiVersion,
}) => {
  const [open, setOpen] = useState(false);
  const Arrow = open ? ArrowUpward : ArrowDownward;
  if (!events) return null;
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link color="black" onClick={() => setOpen(!open)} variant="para.md" hoverEffect="none">
        <Linear align="center" orientation="horizontal">
          <Typography>
            View List of&nbsp;
          </Typography>
          {connector}
          &nbsp;Webhook to setup
          &nbsp;
          <Arrow fontSize="medium" />
        </Linear>
      </Link>
      <Collapse
        in={open}
        Component={Card}
        gap="lg"
        variant="shiny"
      >
        <Typography variant="para.sm">
          Make sure the following webhooks are setup in your&nbsp;
          {connector}
          &nbsp;account settings.
        </Typography>
        {events.map(({ event, ...rest }, index) => (
          <WebhookInfo
            connectorWebhookUrlPrefix={connectorWebhookUrlPrefix}
            event={event}
            index={index}
            key={event}
            {...rest}

            connectorId={connectorId}
            tenantId={tenantId}
            webhookApiVersion={webhookApiVersion}
          />
        ))}
      </Collapse>
    </>
  );
};

export const ConnectorGuide = ({
  connector,
  guideLink,
}) => (
  <Linear orientation="vertical" width="100pr">
    <Spacer y="sm" />
    <Card gap={false} variant="shiny" width="100pr">
      <Typography variant="para.xl" size="xl" weight="medium" font="header">
        {`Learn more about ${connector} integration.`}
      </Typography>
      <Spacer y="md" />
      <Typography variant="para.sm">
        {`Learn how to setup your ${connector} connector settings and capabilities. Read our ${connector} connector guide.`}
      </Typography>
      <Spacer y="lg" />
      <Link hoverEffect="none" newTab href={guideLink}>
        <FormButton color="secondary" size="sm">READ DOCS</FormButton>
      </Link>
    </Card>
  </Linear>
);

export const sortBy = (field) => (x, y) => x[field].localeCompare(y[field], undefined, { numeric: true, sensitivity: 'base' });

const nameSort = sortBy('name');

export const MappingTable = (props) => {
  const {
    carriyoEntities,
    disabled,
    getRemoteEntities,
    mappings = {},
    onChange,
    sourceLabel,
    targetLabel,

    connector = {},
    lastSaved = {},

    CarriyoEntityComponent = UnifiedInput,
    CarriyoEntityComponentProps = {},

    reverseColumns,
    variant = 'standard',
  } = props;

  const { connectorId } = connector;
  const {
    // Shopify
    shopifyAccessToken,
    shopifyApiKey,
    shopifyApiSecret,
    shopifyDomainName,

    // WooCommerce
    baseUrl,
    consumerKey,
    consumerSecret,
  } = lastSaved;

  const [loading, setLoading] = useState(false);

  const [remoteEntities] = useAsyncState(async () => {
    const shouldFetch = (
      connectorId
      && (
        (shopifyDomainName && (
          shopifyAccessToken
          || (shopifyApiKey && shopifyApiSecret)
        ))
        || (baseUrl && consumerKey && consumerSecret)
      )
    );
    setLoading(true);
    const { data = [] } = shouldFetch
      ? await getRemoteEntities(connectorId)
      : {};
    setLoading(false);
    return data.filter((entry) => !isEmpty(entry));
  }, [], [
    connectorId,

    // only saved values trigger requests
    // as remote APIs have rate limits
    baseUrl,
    consumerKey,
    consumerSecret,
    shopifyAccessToken,
    shopifyApiKey,
    shopifyApiSecret,
    shopifyDomainName,
  ]);

  const isMinimal = variant === 'minimal';

  const columns = [{
    columnId: kebabCase(sourceLabel),
    label: sourceLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({ row: { remoteEntityName } }) => (
      <Tooltip title={remoteEntityName}>
        <Typography variant="para.xs:body" className="u-ellipsis">
          {remoteEntityName}
        </Typography>
      </Tooltip>
    ),
  }, ...(isMinimal ? [{
    align: 'center',
    columnId: 'arrow',
    label: '',
    sortable: false,
    width: 'auto',
    CellComponent: () => <ArrowForward color="primary" size="large" />,
  }] : []), {
    columnId: kebabCase(targetLabel),
    label: targetLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({
      row: { carriyoEntityId, remoteEntity },
    }) => (
      <CarriyoEntityComponent
        field="mapping"
        disabled={disabled}
        options={carriyoEntities}
        onChange={(newEntityId) => onChange(remoteEntity, newEntityId, carriyoEntityId)}
        value={carriyoEntityId}
        {...CarriyoEntityComponentProps}
      />
    ),
  }];

  if (reverseColumns) columns.reverse();

  const entries = remoteEntities.sort(nameSort).map(({ name, id }) => ({
    carriyoEntityId: mappings[id],
    remoteEntity: { name, id: `${id}` },
    remoteEntityName: name,
  }));

  return (
    <Linear width="md">
      <Table
        columns={columns}
        header={{ color: isMinimal ? 'transparent' : undefined }}
        loading={loading}
        margin={false}
        rows={entries}
        variant="outlined"
      />
    </Linear>
  );
};

function toMappings(rows) {
  return Object.fromEntries(rows.map((item) => [item.code, item.value]));
}

export const CodeMappingTable = ({
  carriyoEntities,
  disabled,
  mappings = {},
  onChange,
  sourceLabel,
  targetLabel,
  UnifiedInputOverride = UnifiedInput,
  UnifiedInputOverrideProps = {},
}) => {
  const [rows, setRows] = useState([]);

  // Expand the mapping first time, and not again
  useEffect(() => {
    const currentMappings = toMappings(rows);
    if (!isEqual(currentMappings, mappings)) {
      setRows(
        Object.entries(mappings).map(([code, value]) => ({
          value,
          code,
        })),
      );
    }
  }, [mappings]);

  const columns = [{
    columnId: kebabCase(sourceLabel),
    label: sourceLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({ row: { code, value }, rowIndex }) => (
      <UnifiedInput
        field="textKey"
        value={code}
        onChange={(event) => {
          const newCode = event.target.value;
          const newRows = [].concat(
            rows.slice(0, rowIndex),
            { code: newCode, value },
            rows.slice(rowIndex + 1),
          );
          setRows(newRows);
          onChange(toMappings(newRows));
        }}
      />
    ),
  }, {
    columnId: kebabCase(targetLabel),
    label: targetLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({ row: { value, code }, rowIndex }) => (
      <UnifiedInputOverride
        disabled={disabled}
        options={carriyoEntities}
        onChange={(newValue) => {
          const newRows = [].concat(
            rows.slice(0, rowIndex),
            { code, value: newValue },
            rows.slice(rowIndex + 1),
          );
          setRows(newRows);
          onChange(toMappings(newRows));
        }}
        value={value}
        {...UnifiedInputOverrideProps}
      />
    ),
  }, {
    columnId: 'action',
    label: 'Action',
    width: '70px',
    sortable: false,
    align: 'center',
    CellComponent: ({ row: { code }, rowIndex }) => (
      <Menu
        color="primary"
        options={[{
          Icon: DeleteIcon,
          key: code,
          label: 'Delete',
          color: 'default',
          onClick: () => {
            const newRows = [].concat(
              rows.slice(0, rowIndex),
              rows.slice(rowIndex + 1),
            );
            setRows(newRows);
            onChange(toMappings(newRows));
          },
          show: true,
        }]}
      />
    ),
  }];

  return (
    <>
      <Linear
        width="lg"
        align="center"
        justify="end"
        orientation="horizontal"
      >
        <TableAddButton
          onClick={() => setRows(
            rows.concat({ code: '', value: '' }),
          )}
        >
          Add Record
        </TableAddButton>
      </Linear>
      <Linear width="lg">
        <Table
          columns={columns}
          margin={false}
          rows={rows}
          rowIdField="code"
          variant="outlined"
        />
      </Linear>
    </>
  );
};

const abortActions = ({ asyncTask, update }) => [{
  color: 'primary',
  overrideWaiting: true,
  onClick: async () => {
    const { data, error } = await abortAsyncTask(asyncTask);
    if (!error) {
      update({ asyncTask: data });
      updateAsyncTasks([data], true);
    }
  },
  title: 'Abort',
}];

const asyncTaskReEntryStep = 'Preparing';

const syncItemsSteps = [];

const asyncTaskPoller = async (state) => {
  const step = makeProgressStepper(syncItemsSteps, state);
  const { asyncTask: { taskId } = {}, update } = state;
  while (taskId) {
    const { data: asyncTask, error } = await getAsyncTask({ taskId });
    if (error) break; else {
      update({ asyncTask });
      updateAsyncTasks([asyncTask], true);
      if (asyncTask.status !== 'progress') {
        step.goto(asyncTask.status);
        if (asyncTask.status !== 'aborting') {
          break;
        }
      } else {
        step.goto(asyncTask.stage);
      }
      await delay(250);
    }
  }
  update({ waiting: false });
};

// item steps are pushed into syncItemsSteps after (due to reference from asyncTaskPoller)
[{
  stepId: 'summary',
  actions: ({
    onClose,
    startSync,
    syncStyle,
    step,
    update,
    waiting,
  }) => [{
    color: 'secondary',
    onClick: onClose,
    overrideWaiting: true,
    state: waiting ? 'disabled' : 'ready',
    title: 'No',
  }, {
    color: 'primary',
    disabled: !syncStyle,
    onClick: async () => {
      update({ waiting: true });
      const { data: asyncTask = {}, error } = await startSync({ delete: syncStyle === 'fresh' });
      if (error) {
        update({ waiting: false });
      } else {
        update({ asyncTask, onAsync: asyncTaskPoller });
        updateAsyncTasks([asyncTask], true);
        step.goto(asyncTask.stage);
      }
    },
    title: 'Yes, Confirm',
  }],
  Content: ({ syncStyle, update, waiting }) => (
    <Linear align="center" height="100pr" gap="md" justify="center" orientation="vertical" width="100pr">
      <Typography variant="para.sm:body">
        This will begin transferring products from Shopify to Carriyo.
        <br />
        Would you like to,
      </Typography>
      <Radio
        disabled={waiting}
        options={[{
          label: 'Overwrite the existing catalog',
          value: 'fresh',
        }, {
          label: 'Merge to the existing catalog',
          value: 'merge',
        }]}
        onChange={({ target }) => update({ syncStyle: target.value })}
        value={syncStyle}
      />
      <Typography variant="para.sm:body">
        Do you want to continue?
      </Typography>
    </Linear>
  ),
}, {
  stepId: asyncTaskReEntryStep,
  onMinimize: ({ update }) => () => update({ visible: false }),
  actions: abortActions,
  Content: () => (
    <Linear align="center" gap="2xl" height="100pr" justify="center" orientation="vertical" width="100pr">
      <CircularProgress size={80} />
      <Typography color="secondary" variant="h4">
        Preparing for item upload...
      </Typography>
    </Linear>
  ),
}, {
  stepId: 'Deleting',
  actions: abortActions,
  onMinimize: ({ update }) => () => update({ visible: false }),
  Content: ({ asyncTask }) => (
    <Linear align="center" gap="2xl" height="100pr" justify="center" orientation="vertical" width="100pr">
      <CircularProgress size={80} />
      <Typography color="secondary" variant="h4">
        {`Deleted ${asyncTask.progress.current} items`}
      </Typography>
    </Linear>
  ),
}, {
  stepId: 'Uploading',
  onMinimize: ({ update }) => () => update({ visible: false }),
  actions: abortActions,
  Content: ({ asyncTask: { progress } }) => (
    <Linear align="center" gap="2xl" height="100pr" justify="center" orientation="vertical" width="100pr">
      <CircularProgress size={80} value={(progress.current / progress.total) * 100} />
      <Typography color="secondary" variant="h4">
        {`Uploading ${progress.current} out of ${progress.total}`}
      </Typography>
    </Linear>
  ),
}, {
  stepId: 'aborting',
  onMinimize: ({ update }) => () => update({ visible: false }),
  Content: () => (
    <Linear align="center" gap="2xl" height="100pr" justify="center" orientation="vertical" width="100pr">
      <CircularProgress size={80} />
      <Typography color="secondary" variant="h4">
        Aborting...
      </Typography>
    </Linear>
  ),
}, ...Object.entries({
  success: 'Success!',
  error: 'Failed at "{{stage}}" stage!',
  aborted: 'Aborted at "{{stage}}" stage!',
}).map(([stepId, title]) => ({
  stepId,
  actions: ({ onClose }) => [{
    color: 'secondary',
    onClick: onClose,
    title: 'Close',
  }],
  Content: ({ asyncTask: { error, progress, stage } }) => {
    const [showErrors, setShowErrors] = useState(false);
    const toggleErrorDialog = () => setShowErrors(!showErrors);
    return (
      <Linear align="center" gap="xl" height="100pr" justify="center" orientation="vertical" width="100pr">
        <Typography color="secondary" variant="h4">
          {title.replace('{{stage}}', stage)}
        </Typography>
        <Linear orientation="vertical" width="100pr">
          <Linear align="center" gap="md" height="100pr" orientation="vertical" width="100pr">
            <Typography variant="para.sm:body">
              {stage === 'Deleting' ? (
                `Deleted ${progress.current} items.`
              ) : (
                `Uploaded ${progress.current} active items out of ${progress.total} entries.`
              )}
            </Typography>
            {stepId === 'error' && (
              <>
                <Spacer y="md" />
                <Typography color="error" variant="para.sm">
                  {error.message}
                </Typography>
                <Button onClick={toggleErrorDialog} size="sm">
                  Show Error Details
                </Button>
              </>
            )}
            <Dialog
              onClose={toggleErrorDialog}
              open={showErrors}
              size="lg"
              title="Error Details"
            >
              <div
                style={{
                  padding: '8px',
                  backgroundColor: '#FCFCFC',
                  boxShadow: 'inset 0px 1px 2px 0px #CCC, inset 0px -1px 2px 0px #EEE',
                  fontFamily: 'monospace',
                  width: '100%',
                  borderRadius: '4px',
                  margin: '2px 0',
                  height: '410px',
                  maxHeight: '410px',
                  overflow: 'scroll',
                }}
              >
                <JsonView
                  src={error?.additionalInfo || {}}
                  enableClipboard={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              </div>
            </Dialog>
          </Linear>
        </Linear>
      </Linear>
    );
  },
}))].forEach((step) => syncItemsSteps.push(step));

const synchronizeDialogHeading = 'Synchronize Item Catalog';

const SyncItemsDialog = makeProgressDialog({
  steps: syncItemsSteps,
  size: 'md',
  title: synchronizeDialogHeading,
  type: 'wizard',
});

const commonIconProps = { style: { height: 15, width: 15 } };

const SyncItemsSummary = ({
  asyncTask: {
    error,
    progress,
    stage,
    status,
  } = {},
}) => {
  const progressText = (
    <Typography>
      {`${progress.current}${progress.total ? ` / ${progress.total}` : ''} entries`}
    </Typography>
  );

  return ['aborting', 'progress'].includes(status) ? ( // eslint-disable-line no-nested-ternary
    <>
      <Linear gap="sm" orientation="horizontal">
        <CircularProgress
          size={15}
          value={progress.total ? (progress.current / progress.total) * 100 : undefined}
        />
        <Typography>
          {status === 'progress' ? stage : startCase(status)}
        </Typography>
      </Linear>
      {progressText}
    </>
  ) : status === 'error' ? (
    <>
      <Linear gap="sm" orientation="horizontal">
        <WarningIcon color="error" {...commonIconProps} />
        <Typography>
          {stage}
        </Typography>
      </Linear>
      <Typography>
        {error.message}
      </Typography>
    </>
  ) : (
    <>
      <Linear gap="sm" orientation="horizontal">
        {status === 'success' ? (
          <CheckedIcon color="secondary" {...commonIconProps} />
        ) : (
          <WarningIcon color="error" {...commonIconProps} />
        )}
        <Typography>
          {status === 'success' ? 'Finished' : startCase(status)}
        </Typography>
      </Linear>
      {progressText}
    </>
  );
};

export const syncItemsActivityParams = ({
  asyncTask,
  startSync,
  step = syncItemsSteps.findIndex(({ stepId }) => stepId === asyncTaskReEntryStep),
}) => ({
  heading: synchronizeDialogHeading,
  Content: SyncItemsDialog,
  Summary: SyncItemsSummary,
  onClose: (_, state) => (state.asyncTask ? dismissAsyncTask(state.asyncTask) : null),
  ...(step === 0 ? {} : { onAsync: asyncTaskPoller }),
  asyncTask,
  startSync,
  step,
  syncStyle: null,
  visible: true,
  waiting: false,
});
