import React, { forwardRef } from 'react';

import cls from '../helpers/cls';

import './Card.css';

import Linear from './Linear';
import Spacer from './Spacer';
import Typography from './Typography';

const Card = forwardRef(({
  children,
  className,
  corner = 'md',
  description,
  gap = 'xl',
  label,
  labelProps = {},
  orientation = 'vertical',
  padding = 'md',
  trail,
  variant,
  ...rest
}, ref) => (
  <Linear
    {...rest}
    className={cls('Card', { corner, variant }, className)}
    gap={gap}
    orientation={orientation}
    padding={padding}
    ref={ref}
  >
    {(label || description || trail) && (
      <Linear orientation="vertical" width="100pr" gap="xs">
        <Linear orientation="horizontal" align="center" width="100pr">
          {label && (
            <Typography variant="para.lg:head" {...labelProps}>
              {label}
            </Typography>
          )}
          <Spacer flex />
          {trail}
        </Linear>
        {description && (
          <Typography variant="para.sm:body">
            {description}
          </Typography>
        )}
      </Linear>
    )}
    {children}
  </Linear>
));

export default Card;
