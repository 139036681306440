import { map } from 'lodash';

import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';

import { updateGlobal } from '../store';

import * as loadingActions from './loading';
import * as reasonCodesApi from '../api-client/reason-code';
import { reader } from '../helpers/action';

const entity = 'reason codes';

export const list = reader(
  entity,
  async ({ bustCache = false } = {}) => {
    const { data = [] } = await reasonCodesApi.list({ bustCache });
    const reasonCodes = camelCaseKeys(data);
    updateGlobal({ reasonCodes });
    return reasonCodes;
  },
);

export const bulkAction = async (operation, reasonCodes) => {
  const action = async (reasonCode) => {
    try {
      return await reasonCodesApi[operation](
        snakeCaseKeys(reasonCode),
      );
    } catch (error) {
      return { error };
    }
  };

  loadingActions.showLoadingState();
  const statuses = await Promise.all(
    map(
      reasonCodes,
      (reasonCode) => {
        const { type } = reasonCode;
        return type === 'CUSTOM'
          ? action(reasonCode)
          : {};
      },
    ),
  );
  loadingActions.hideLoadingState();
  return map(
    reasonCodes,
    (reasonCode, index) => ({
      data: reasonCode,
      error: statuses[index].error,
    }),
  );
};
