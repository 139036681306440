import React, { useState } from 'react';

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import blockEvent from 'lib-frontend-shared/src/helpers/blockEvent';
import cls from 'lib-frontend-shared/src/helpers/cls';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Typography from 'lib-frontend-shared/src/components/Typography';
import './InputLabel.scss';
import Tooltip from '../../Tooltip';

const labelPositionParamMap = {
  top: {
    orientation: 'vertical',
    reverse: false,
  },
  bottom: {
    orientation: 'vertical',
    reverse: true,
  },
  left: {
    orientation: 'horizontal',
    reverse: false,
  },
  right: {
    orientation: 'horizontal',
    reverse: true,
  },
};

const InputLabel = React.forwardRef((props, ref) => {
  const {
    className = '',
    disabled,
    Input,
    inputProps = {},
    inputProps: {
      width: inputWrapperWidth = '100Pr',
    } = {},
    issues = [],
    onBlur: externalOnBlur = blockEvent,
    onFocus: externalOnFocus = blockEvent,
    width = 'full',
    label,
    description,
    required,
    labelProps: {
      position: labelPosition = 'top', width: labelWrapperWidth, ...labelProps
    } = {},
    descriptionProps: {
      position: descriptionPosition = 'top', ...descriptionProps
    } = {},
    style,
    field,
    helpMessage = null,
    ...rest
  } = props;

  const [focused, setFocused] = useState(false);

  const onBlur = (event) => {
    setFocused(false);
    return externalOnBlur(event);
  };

  const onFocus = (event) => {
    setFocused(true);
    return externalOnFocus(event);
  };

  const hasIssue = issues.length > 0;
  const descriptionPartial = description && (
    <Typography disabled={disabled} variant="para.sm:body" {...descriptionProps}>
      {description}
    </Typography>
  );

  const labelParam = labelPositionParamMap[labelPosition];

  const component = (
    <Linear orientation="vertical" width={inputWrapperWidth} className="Input">
      <div
        className={cls(
          'InputLabel-inputWrapper',
          {
            disabled,
            focused: focused && !hasIssue,
            error: hasIssue,
          },
        )}
      >
        <Input
          {...rest}
          disabled={disabled}
          ref={ref}
          onFocus={onFocus}
          onBlur={onBlur}
          inputProps={inputProps}
          required={required}
          {...inputProps}
        />
      </div>
      <div className="InputLabel-errors" style={{ height: issues.length * 16 }}>
        <div />
        {issues.map((issue) => (
          <Typography color="error" key={issue} variant="para.xs">
            {`- ${issue}`}
          </Typography>
        ))}
      </div>
    </Linear>
  );

  return label ? (
    <Linear className={`InputLabel-width--${width} ${className}`} gap="2xs" orientation="vertical" width={width} style={style}>
      <Linear
        width={labelWrapperWidth}
        align={(
          labelParam.orientation === 'horizontal'
          && !(description && descriptionPosition === 'top')
        ) ? 'center' : 'start'}
        gap={labelParam.orientation === 'horizontal' ? 'lg' : 'sm'}
        {...labelParam}
      >
        <Linear gap="sm" orientation="vertical">
          <Typography disabled={disabled} nowrap {...labelProps} variant={`para.sm${description ? '' : ':body'}`}>
            <Linear align="center" gap="xs">
              <label htmlFor={field}>
                {label}
                {required ? '*' : ''}
              </label>
              {helpMessage && (
                <Tooltip title={helpMessage}>
                  <HelpOutlineOutlinedIcon fontSize="small" className="InputLabel-helpMessageIcon" />
                </Tooltip>
              )}
            </Linear>
          </Typography>
          {descriptionPosition === 'top' && descriptionPartial}
        </Linear>
        {component}
      </Linear>
      {descriptionPosition === 'bottom' && descriptionPartial}
    </Linear>
  ) : (
    <Linear className={`InputLabel-width--${width} ${className}`} style={style}>
      {component}
    </Linear>
  );
});

export default InputLabel;
