import { get } from 'lodash';
import { getCookie, setCookie } from './cookie';
import auth0Client from './auth0client';
import { sendEvent } from './broadcastChannel';
import { setStates, getStates } from './store';
import getInitialState from './getInitialState';

const appName = 'dashboard';

export function isLogoutRequested() {
  return get(getCookie(), ['logoutRequested', appName], false);
}

const logoutCookies = () => {
  const logoutRequested = get(getCookie(), 'logoutRequested', {});
  setCookie({
    logoutRequested: {
      ...logoutRequested,
      [appName]: false,
    },
  });
};

const logoutLocalStorage = () => {
  localStorage.removeItem('auth');
  localStorage.removeItem('idTokenPayload');
  localStorage.removeItem('lastSessionCheck');
};

// 'Clear login' doesn't mean fully logged out.
// It gives app a chance to recheck with auth0 for a new access token
// If auth0 is already logged in, it will return a new token without
// having to resubmit username/password.
export const clearLogin = () => {
  logoutCookies();
  logoutLocalStorage();
  setStates({
    ...getStates(),
    auth: getInitialState().auth,
  });
};

export async function logout(requestOtherAppsToLogout = true) {
  if (requestOtherAppsToLogout) {
    setCookie({
      expiresAt: undefined,
      lastLogin: undefined,
      lastLogout: Date.now(),
      logoutRequested: {
        account: true,
        storeapp: true,
        [appName]: false,
        requestedBy: appName,
      },
    });
    // logout all apps across tabs
    await sendEvent('logoutRequest', true);
  } else {
    logoutCookies();
  }

  logoutLocalStorage();
  auth0Client.logout({
    returnTo: window.location.origin,
    federated: '',
  });
}
