import React, { useState } from 'react';
import {
  TextField as MuiTextField,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Typography from 'lib-frontend-shared/src/components/Typography';
import './TextFields.scss';

const activeBorderStyle = {
  borderColor: '#00063b',
  borderWidth: '1px',
};

const commonOutlinedProps = {
  background: 'transparent',
  borderRadius: '10px',
  '& fieldset': {
    borderColor: '#777777',
    borderWidth: '1px',
    transition: 'border-color 0.125s linear',
  },
  '&:hover fieldset': activeBorderStyle,
  '&.Mui-focused fieldset': activeBorderStyle,
  '&.Mui-disabled:hover fieldset': {
    borderColor: 'rgba(0, 0, 0, 0.26)',
    borderWidth: '1px',
  },
  '&.MuiOutlinedInput-multiline': { padding: 0 },
};

const commonTextProps = {
  color: '#202020',
  '&:hover': { color: '#00063b' },
  '&:focus': { color: '#00063b' },
  '&:disabled': {
    color: '#808080',
    '&::placeholder': {
      color: '#AAAAAA',
    },
  },
};

export const TextField = withStyles(() => ({
  root: {
    '& .MuiInput-input': {
      ...commonTextProps,
      '&:out-of-range': {
        'border-bottom': 'none',
      },
    },
    '& .MuiOutlinedInput-root': commonOutlinedProps,
    '& .MuiOutlinedInput-input': { padding: '10px 15px' },
  },
}))(MuiTextField);

const commonAutocompleteOutlinedProps = {
  ...commonOutlinedProps,
  padding: '0 5px',
};

export const AutocompleteTextField = withStyles(() => ({
  root: {
    '& .MuiInput-input': commonTextProps,
    '& .MuiOutlinedInput-root': commonAutocompleteOutlinedProps,
  },
}))(MuiTextField);

export const NarrowAutocompleteTextField = withStyles(() => ({
  root: {
    '& .MuiInput-input': commonTextProps,
    '& .MuiOutlinedInput-root': commonAutocompleteOutlinedProps,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '0 5px',
    },
  },
}))(MuiTextField);

const generateInputField = (InputFieldComponent) => (Renderer) => ({
  InputProps,
  inputProps: {
    style,
    value,
    ...rawInputProps
  } = {},
  placeholder,
  onFocus = () => {},
  onBlur = () => {},
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputProps = {
    ...rawInputProps,
    style: {
      ...style,
      color: isFocused ? style?.color : 'transparent',
    },
  };
  const spreadProps = {
    ...(Renderer
      ? { inputProps }
      : { placeholder, inputProps: { ...inputProps, value } }
    ),
    ...rest,
  };
  return (
    <InputFieldComponent
      InputProps={{
        ...InputProps,
        startAdornment: (!Renderer || isFocused) ? undefined : (
          <div className="TextFields-autocompleteAdornment">
            {value ? (
              <Renderer value={value} />
            ) : (
              <Typography italic variant="para.xs">
                {placeholder}
              </Typography>
            )}
          </div>
        ),
      }}
      {...spreadProps}
      onFocus={(event) => {
        setIsFocused(true);
        onFocus(event);
      }}
      onBlur={(event) => {
        setIsFocused(false);
        onBlur(event);
      }}
      value={value}
    />
  );
};

export const CustomAutocompleteTextField = generateInputField(AutocompleteTextField);
export const CustomNarrowAutocompleteTextField = generateInputField(NarrowAutocompleteTextField);
