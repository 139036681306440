import { carriyoClient, getCacheBustHeaders } from './base';

export const list = async ({ bustCache = false }) => carriyoClient.get(
  '/reason-codes',
  {
    headers: { ...(bustCache ? getCacheBustHeaders() : {}) },
    params: { cache: 'true' },
  },
);

export const create = async (
  reasonCode,
) => carriyoClient.post(
  '/reason-codes',
  reasonCode,
);

export const remove = async (
  { code },
) => carriyoClient.delete(
  `/reason-codes/${code}`,
);

export const update = async (
  reasonCode,
) => carriyoClient.put(
  `/reason-codes/${reasonCode.code}`,
  reasonCode,
);
