import './polyfills';

// -- react, router --
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router as ReactRouter } from 'react-router-dom';
import sessionStore from 'lib-frontend-shared/src/helpers/sessionStore';

// -- custom --
import { history } from './store';
import Router from './components/router/Router';
import GlobalInitializer from './components/global/Initializer';
import ScrollToTop from './components/scroll-restoration/ScrollToTop';
import config from './config';
import './logger';
import './index.scss';

const { userAgent } = navigator;

const App = () => {
  if (userAgent.match(/AppleWebKit/) && !userAgent.match(/Chrome/)) {
    document.body.classList.add('safari');
  }
  useEffect(() => {
    window.addEventListener('unload', () => {
      sessionStore.delete('sideNavScrollPos');
    });
  }, []);
  useEffect(() => {
    if (!config.gtag || typeof window === 'undefined') return undefined;
    /* eslint-disable no-undef */
    gtag('js', new Date());
    gtag('config', config.gtag);
    /* eslint-disable no-undef */
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${config.gtag}`;
    document.head.append(script);
    return () => script.remove();
  }, []);
  return (
    <>
      <GlobalInitializer />
      <ReactRouter history={history}>
        <ScrollToTop />
        <Router />
      </ReactRouter>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
