import React from 'react';
import cls from '../helpers/cls';
import './Divider.css';

const Divider = (props) => {
  const {
    color = 'disabled',
    className,
    length = '100pr',
    orientation = 'horizontal',
    padding,
    weight = 'thin',
    ...rest
  } = props;
  const classes = cls('Divider', {
    length, orientation, padding, weight,
  }, className);
  return (
    <div className={classes} {...rest}>
      <div className={cls('Divider-stroke', { color })} />
    </div>
  );
};

export default Divider;
