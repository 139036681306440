/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import cls from '../helpers/cls';

import Linear from './Linear';
import Typography from './Typography';

import './Radio.css';

const Radio = ({
  className: classes,
  color = 'secondary',
  disabled,
  direction = 'vertical',
  onChange,
  options = [],
  id: name,
  value: selected,
  ...rest
}) => (
  <fieldset className={cls('Radio', { color, disabled, direction }, classes)}>
    {options.map(({
      description,
      descriptionProps = {},
      entryProps = {},
      label,
      labelProps = {},
      radioProps = {},
      value = null,
    }) => (
      <label
        className="Radio-label"
        key={typeof label === 'string' ? label : value}
        {...entryProps}
      >
        <div className="Radio-inputWrapper">
          <input
            {...rest}
            {...radioProps}
            checked={selected === value}
            disabled={disabled}
            onChange={(event) => onChange(
              { target: { value: event.target.value || value } },
              event,
            )}
            name={name}
            type="radio"
            value={value}
          />
        </div>
        <Linear gap="sm" orientation="vertical">
          <Typography variant="para.sm" {...labelProps}>
            {label}
          </Typography>
          {description && (
            <Typography variant="para.sm:body" {...descriptionProps}>
              {description}
            </Typography>
          )}
        </Linear>
      </label>
    ))}
  </fieldset>
);

export default Radio;
