import { isEqual } from 'lodash';
import { getStates } from '../store';

export default (selectedMerchantIdsProps = []) => {
  const {
    global: { selectedMerchantIds: selectedMerchantIdsState = [], merchantIds = [] },
  } = getStates();
  const selectedMerchantIds = selectedMerchantIdsProps.length
    ? selectedMerchantIdsProps
    : selectedMerchantIdsState;
  // For single merchant clients (who dont have account level feature), we should retain merchant parameter
  // that is require to fetch the merchant level service rules
  return selectedMerchantIds.length > 1 && isEqual(selectedMerchantIds.sort(), merchantIds.sort());
};
