import moment from './moment';

export default (text, format = 'dateObject') => {
  const from = moment().local();
  const to = moment().local();
  const parts = text.replace(/-/g, ' ').split(' ');
  let date;
  switch (parts[0]) {
    case 'this':
      // this month
      date = {
        from: from.startOf(parts[1]),
        to,
      };
      break;
    case 'last': {
      // last 30 days
      // or last month
      const unit = parts.slice(-1)[0];
      if (parts.length > 2) {
        // handle last 30 days or last 3 months
        // it should include today or this month or this year as well
        const amount = Number(parts[1]);
        date = {
          from: from.subtract(amount, unit).startOf(unit),
          to: to.endOf(unit),
        };
      } else {
        // handle last month or last year
        // it should not include today or this month or this year
        date = {
          from: from.subtract(1, unit).startOf(unit),
          to: to.subtract(1, unit).endOf(unit),
        };
      }
      break;
    }
    case 'today':
      date = {
        from: from.startOf('day'),
        to,
      };
      break;
    case 'yesterday':
      date = {
        from: from.subtract(1, 'day').startOf('day'),
        to: to.subtract(1, 'day').endOf('day'),
      };
      break;
    default:
      date = { from, to };
  }

  switch (format) {
    case 'dateObject': return date;
    default: return {
      from: date.from.format(format),
      to: date.to.format(format),
    };
  }
};
