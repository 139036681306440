function promiseCacher(promiseReturningFunc) {
  const promiseCache = {};
  return (cacheKey, ...args) => {
    if (!cacheKey) {
      return new Error('cacheKey cannot be undefined, null or empty string. It has to be a string with at least one character');
    }
    if (!promiseCache[cacheKey]) {
      promiseCache[cacheKey] = (async () => {
        let val;
        try {
          val = await promiseReturningFunc(cacheKey, ...args);
          delete promiseCache[cacheKey];
        } catch (err) {
          delete promiseCache[cacheKey];
          throw err;
        }
        return val;
      })();
    }
    // FIXME: should we clone return object for each promise?
    return promiseCache[cacheKey];
  };
}

export default promiseCacher;
