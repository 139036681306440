import { pickBy } from 'lodash';
import hashFunc from 'fnv1a';
import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import moment from '../helpers/moment';

import {
  carriyoClient,
  nodeMiddlewareClient,

  // utils
  getBackendHeadersToAdd,
  getCacheBustHeaders,
} from './base';
import { getStates } from '../store';
import {
  fromCustomAttribute,
  fromOrderType,
  fromDeliveryType,
  toOrderType,
  toDeliveryType,
  toCustomAttribute,
} from '../api-transforms';

export const getUserDefinedEnums = async ({
  props,
  bustCache,
}) => {
  const states = getStates();
  const {
    auth: {
      tenantId,
      carriyoUserId,
      merchants: authMerchants,
    },
  } = states;

  const { data } = await nodeMiddlewareClient.get('/enums', {
    headers: {
      ...getBackendHeadersToAdd(),
      'user-tz': moment.tz.guess(),
      ...(bustCache ? getCacheBustHeaders() : {}),
    },
    params: {
      props,
      // cache busting
      v: hashFunc([
        tenantId,
        carriyoUserId,
        JSON.stringify(authMerchants),
        'v2',
      ].filter(Boolean).join('-')),
    },
  });

  const {
    tenantSettings,
    merchants, // all accessible merchants
    carrierMetadata,
    orderTypes,
    deliveryTypes,
    customAttributes,
  } = data;
  return {
    tenantSettings: camelCaseKeys(tenantSettings),
    merchants,
    carrierMetadata,
    orderTypes: orderTypes.map(fromOrderType),
    deliveryTypes: deliveryTypes.map(fromDeliveryType),
    customAttributes: customAttributes
      .filter((item) => item.attribute_entity_type === 'SHIPMENT')
      .map(fromCustomAttribute),
    addressCustomAttributes: customAttributes
      .filter((item) => item.attribute_entity_type === 'ADDRESS')
      .map(fromCustomAttribute),
  };
};

const blankValues = [false, null, undefined];

const sanitizeParams = (params = {}) => pickBy(params, (value) => !blankValues.includes(value));

// STATES
/**
 * @returns {Promise<{
    country_code: string,
    state_code: string,
    state_names: {language: string, name: string}[],
   }[]>}
 */
export const getStateList = async (params) => {
  const { auth: { tenantId } } = getStates();
  const { data = [] } = await carriyoClient.get('/states', {
    params: {
      ...sanitizeParams(params),
      cache: 'true',
    },
    headers: {
      'tenant-id': tenantId,
    },
  });
  return data;
};

// CITIES
/**
 * @returns {Promise<{
    city_code: string,
    city_coordinates:  string,
    city_names: {language: string, name: string}[],
    country_code: string,
    deprecated: boolean,
    state_code: string,
   }[]>}
 */
export const getCityList = async (params) => {
  const { auth: { tenantId } } = getStates();
  const { data = [] } = await carriyoClient.get('/cities', {
    params: {
      ...sanitizeParams(params),
      cache: 'true',
    },
    headers: {
      'tenant-id': tenantId,
    },
  });
  return data;
};

// AREAS
export const getAreaList = async (params) => {
  const { data = [] } = await carriyoClient.get('/areas', {
    params: sanitizeParams(params),
  });
  return camelCaseKeys(data);
};


// CUSTOM ATTRIBUTES

export const addCustomAttribute = async (payload) => {
  const { data } = await carriyoClient.post('/attributes', toCustomAttribute(payload));
  return { data: fromCustomAttribute(data) };
};

export const updateCustomAttribute = async (payload) => {
  const { data } = await carriyoClient.put(`/attributes/${payload.customAttributeId}`, toCustomAttribute(payload));
  return { data: fromCustomAttribute(data) };
};

export const deleteCustomAttribute = async ({ customAttributeId }) => {
  const { data } = await carriyoClient.delete(`/attributes/${customAttributeId}`);
  return fromCustomAttribute(data);
};

// ORDER TYPES

export const addOrderType = async (payload) => {
  const { data } = await carriyoClient.post('/order-types', toOrderType(payload));
  return toOrderType(data);
};

export const deleteOrderType = async ({ orderTypeId }) => {
  const { data } = await carriyoClient.delete(`/order-types/${orderTypeId}`, { data: {} });
  return data;
};

// DELIVERY TYPES

export const addDeliveryType = async (payload) => {
  const { data } = await carriyoClient.post('/delivery-types', toDeliveryType(payload));
  return toDeliveryType(data);
};

export const deleteDeliveryType = async ({ deliveryTypeId }) => {
  const { data } = await carriyoClient.delete(`/delivery-types/${deliveryTypeId}`, { data: {} });
  return data;
};

// Address Model

export const getAddressModels = async (params = {}) => {
  const {
    auth: {
      tenantId,
    },
  } = getStates();
  const { data } = await carriyoClient.get('/address-models', {
    params: {
      ...params,
      v: hashFunc(tenantId),
    },
  });
  return camelCaseKeys(data);
};
