import { createStore } from 'react-pack-mule';
// zustand devtools makes use of redux chrome extension
import { devtools } from 'zustand/middleware';
import { createBrowserHistory } from 'history';
import { camelCase } from 'lodash';

import mapKeyValues from 'lib-frontend-shared/src/helpers/mapKeyValues';

import getInitialState from './getInitialState';

export const history = createBrowserHistory();

export const {
  useGlobalStates,
  getStates,
  setStates,
  updateStates,
  createPropUpdater,
} = createStore(null, devtools(getInitialState));

export const resetStore = (authStates) => setStates({
  ...getInitialState(),
  auth: authStates,
});

export const {
  updateAsyncTasks,
  updateBackground,
  updateGlobal,
  updateCities: updateCitiesByCountry,
  updateStates: updateStatesByCountry,
  updateCitiesByState,
  updateAreas,
  updateAuth,
  updateDialogToDisplay,
  updateLoading,
  updateRoute,
  updateToast,
  updateCachedEntities,

  updateCarrier,
  updateCarrierCapacity,
  updateClicknShipService,
  updateApiClients,
  updateConnectors,
  updateShipmentCosting,
  updateNotificationSettings,
  updateLocationsList,
  updateLocation,
  updateNetwork,
  updateManifest,
  updateMerchantList,
  updatePageViews,
  updateReports,
  updateReturnRequestDetail,
  updateReturnRequestList,
  updateReturnRequestSettings,
  updateReverseShipmentRuleList,
  updateRule,
  updateRuleList,
  updateRuleSetList,
  updateReverseRuleSetList,
  updateRuleSet,
  updateReverseRuleSet,
  updateServiceLevelSetList,
  updateServiceLevelSet,
  updateServiceLevel,
  updateServiceLevelsSettings,
  updateAlerts,
  updateShipmentList,
  updateTenantSettings,
  updateThemeAndBrandConfigs,
  updateTrackingAppConfigs,
  updateFeedbackAppConfigs,
  updatePinpointAppConfigs,
  updateElementTranslations,
  updateUsers,
  updateUserList,
  updateWebhookSettings,
  updateCustomDomains,
  updateEmailDomains,
  updateSystem,
} = mapKeyValues(
  [
  // shared states (or states used by shared components)
    'asyncTasks',
    'background',
    'global',
    'cities',
    'states',
    'citiesByState',
    'areas',
    'auth',
    'dialogToDisplay',
    'loading',
    'route',
    'toast',
    'cachedEntities',

    // (mostly) page specific states
    'carrier',
    'carrierCapacity',
    'clicknShipService',
    'apiClients',
    'connectors',
    'shipmentCosting',
    'notificationSettings',
    'locationsList',
    'location',
    'network',
    'manifest',
    'merchantList',
    'pageViews',
    'reports',
    'returnRequestDetail',
    'returnRequestList',
    'returnRequestSettings',
    'reverseShipmentRuleList',
    'rule',
    'ruleList',
    'ruleSetList',
    'ruleSet',
    'reverseRuleSetList',
    'reverseRuleSet',
    'serviceLevelSetList',
    'serviceLevelSet',
    'serviceLevel',
    'serviceLevelsSettings',
    'shipmentList',
    'tenantSettings',
    'themeAndBrandConfigs',
    'trackingAppConfigs',
    'feedbackAppConfigs',
    'pinpointAppConfigs',
    'elementTranslations',
    'users',
    'userList',
    'webhookSettings',
    'alerts',
    'customDomains',
    'emailDomains',
    'system',
  ],
  (_1, prop) => [camelCase(`update_${prop}`), createPropUpdater(prop)],
);

// for debugging purpose - redux devtool is slowing down dev tools
Object.defineProperty(window, 'globalStates', {
  get: getStates,
  set() {},
});
