import { castArray, get } from 'lodash';
import getPreviousPathInfo from '../helpers/getPreviousPathInfo';
import { updateRoute, history } from '../store';

const { push: historyPush, replace: historyReplace, goBack } = history;

const pushToPathStack = (pathName, explicitRoute, explicitStack = []) => {
  const explicitStackList = castArray(explicitStack).filter(Boolean);
  const { previousRouteStack = [] } = getPreviousPathInfo(explicitStackList, true);
  if (explicitRoute && get(previousRouteStack, '0') !== explicitRoute) {
    previousRouteStack.shift();
  }
  if (pathName) {
    previousRouteStack.unshift(pathName);
  }

  const uniqPreviousRouteStack = [];
  previousRouteStack.forEach((route, index) => {
    if (route !== previousRouteStack[index + 1]) {
      uniqPreviousRouteStack.push(route);
    }
  });
  return uniqPreviousRouteStack;
};
const push = (url, savePath = true, explicitRoute = '', explicitStack) => {
  const { pathname, search } = get(history, 'location', {});
  return historyPush(url, {
    from: pushToPathStack(savePath ? `${pathname}${search || ''}` : null, explicitRoute, explicitStack),
  });
};
const replace = (url, savePath = true, explicitRoute = '', explicitStack) => historyReplace(url, {
  from: pushToPathStack(savePath ? get(history, 'location.pathname') : null, explicitRoute, explicitStack),
});

export { push, replace, goBack };
export const setRoute = updateRoute;
