import { useEffect, useState } from 'react';

/**
 * @param {(currentState: Type) => Promise<newState: Type>} getAsyncState
 * @param {Type} initialState
 * @param {React.DependencyList} [dependencies]
 * @returns {[Type, React.Dispatch<React.SetStateAction<Type>>, () => void]}
 * @template Type
 */
function useAsyncState(
  getAsyncState,
  initialState,
  dependencies = [],
) {
  const [state, setState] = useState(initialState);
  const refetch = async () => setState(await getAsyncState(state));
  useEffect(() => { refetch(); }, dependencies);
  return [state, setState, refetch];
}

export default useAsyncState;
