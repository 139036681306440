import React from 'react';
import cls from '../helpers/cls';

import CheckCircle from '../icons/CheckCircle';
import CheckSquare from '../icons/CheckSquare';
import DashCircle from '../icons/DashCircle';
import DashSquare from '../icons/DashSquare';
import FadedCheckCircle from '../icons/FadedCheckCircle';
import Square from '../icons/Square';

import './Checkbox.css';

const iconSetByShape = {
  circle: {
    dash: DashCircle,
    none: FadedCheckCircle,
    tick: CheckCircle,
  },
  square: {
    dash: DashSquare,
    none: Square,
    tick: CheckSquare,
  },
};

const getIconName = (checked, indeterminate) => {
  if (checked) {
    if (indeterminate) return 'dash';
    return 'tick';
  }
  return 'none';
};

const Checkbox = (props) => {
  const {
    checked = false,
    className: classes,
    color = 'primary',
    onlyFade = false,
    indeterminate = false,
    shape = 'square',
    ...rest
  } = props;
  const className = cls('Checkbox', { color, onlyFade }, classes);
  const SVG = iconSetByShape[shape][getIconName(checked, indeterminate)];
  return (
    <div className={className}>
      <input
        {...rest}
        checked={checked}
        // eslint-disable-next-line react/no-unknown-property
        indeterminate={indeterminate}
        type="checkbox"
      />
      <SVG />
    </div>
  );
};

export default Checkbox;
