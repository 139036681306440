import React from 'react';

const FadedCheckCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path
      d="M512 85.333A426.667 426.667 0 0 1 938.667 512 426.667 426.667 0 0 1 512 938.667 426.667 426.667 0 0 1 85.333 512 426.667
    426.667 0 0 1 512 85.333m0 85.334A341.333 341.333 0 0 0 170.667 512 341.333 341.333 0 0 0 512 853.333 341.333 341.333 0 0 0
    853.333 512 341.333 341.333 0 0 0 512 170.667M469.333 704l-192-192 60.16-60.16 131.84 131.413 238.507-238.08 60.16 60.16L469.333 704z"
      fill="#777777"
    />
  </svg>
);

export default FadedCheckCircle;
